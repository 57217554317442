import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const ChangePasswordVerification = () => {
  const location = useLocation();
  const hasRun = useRef(false); // Ensure the useEffect runs only once

  useEffect(() => {
    if (hasRun.current) return; // Skip if useEffect has already run

    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");

      try {
        const response = await fetch(
          `${API_URL}/verify-change-password?token=${token}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'bmi_api_key': bmi_api_key, 
            }
          }
        );
        const data = await response.json();

        if (data.error) {
          toast.error(data.error || "Something went wrong");
          setTimeout(() => {
            window.location.href = "https://www.beatmyinsurance.com/home";
          }, 5000);
        } else {
          toast.success("Password Changed Successfully");
          setTimeout(() => {
            window.location.href = "https://www.beatmyinsurance.com/home";
          }, 5000);
        }
      } catch (error) {
        toast.error("Error verifying email");
      }
    };

    verifyEmail();
    hasRun.current = true; // Mark the effect as run
  }, [location]);

  return (
    <div>
      <p>Please wait while we verify your request...</p> {/* Message displayed on the page */}
      <ToastContainer />
    </div>
  );
};

export default ChangePasswordVerification;
