import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = "XBRmAQXeQAeKKfaSx33Ii9dr3UG0XaQhJDMRcakv" //process.env.REACT_APP_BMI_API_KEY;

const getTypesService = {
    async getTypes() {
      try {
        
        const response = await fetch(`${API_URL}/types`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'bmi_api_key': bmi_api_key, 
            }
          }); 
        const data = await response.json();
        return data.types; 
      } catch (error) {
        console.error('Failed to fetch types:', error.message);
        throw new Error('Failed to fetch types');
      }
    },
  };
  
  export default getTypesService;
  