import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const sendCodeToMobile = async (formData) => {
  const response = await fetch(`${API_URL}/send-mobile-verification`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  return response.json();
};

const verifyMobileCode = async (formData) => {
    const response = await fetch(`${API_URL}/verify-mobile-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
  
    return response.json();
  };


  export {sendCodeToMobile,verifyMobileCode};