import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const NotificationService = {
  getNotificationsForUser: async (userId) => {
    try {
      const response = await fetch(`${API_URL}/${userId}/notifications`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'bmi_api_key': bmi_api_key, 
          }
        });

      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }

      return await response.json();
    } catch (error) {
      throw new Error('Failed to fetch notifications: ' + error.message);
    }
  },

  markNotificationAsRead: async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/notifications/${notificationId}`, {
        method: 'PUT',
        headers: {
          'bmi_api_key': bmi_api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ read: true }),
      });

      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }

      return await response.json();
    } catch (error) {
      throw new Error('Failed to mark notification as read: ' + error.message);
    }
  },
};

export default NotificationService;
