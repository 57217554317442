import React, { useState, useEffect } from "react";
import buyerPostService from "../services/buyerPostService";
import getTypesService from "../services/getTypesService";
import getStatesService from "../services/getStatesService";
import "./NewListing.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getAdminFieldsService from "../services/dynamicFieldsService";
import positionsService from "../services/indexingService";
import SessionExpired from "./SessionExpired";

const NewListing = ({ user }) => {
  const userData = user;
  const [formData, setFormData] = useState({
    type: "",
    city: "",
    zip: "",
    state: "",
    currentPremium: "",
    expectedPremium: "",
    startDate: "",
    currentProvider: "",
    userEmail: userData.email,
    userId: userData._id,
    description: "",
    visibility: true,
    bids: {},
    additionalData: {},
  });
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [fields, setFields] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState(false);

  useEffect(() => {
    fetchFields();
  }, []);

  const fetchFields = async () => {
    try {
      const fields = await getAdminFieldsService.getAdminFields();
      const positionsResponse = await positionsService.getPositions();
      const positions = positionsResponse.positions;
      const orderedFields = [];
      positions.forEach(id => {
        if(fields[id] !== undefined){
          orderedFields.push(fields[id]);
        }
        
      });
      setFields(orderedFields);
     
    } catch (error) {
      console.error("Failed to fetch fields:", error.message);
    }
  };

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  useEffect(() => {
    fetchTypes();
    fetchStates();
    const storedListing = localStorage.getItem("BMI-newlisting");

    if (storedListing) {
      setVerifyMessage(true);
      const newListingData = JSON.parse(storedListing);

      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData };

        for (const key in newListingData) {
          if (updatedFormData.hasOwnProperty(key)) {
            updatedFormData[key] = newListingData[key];
          }
        }

        return updatedFormData;
      });

      localStorage.removeItem("BMI-newlisting");
    }
  }, []);

  const fetchTypes = async () => {
    try {
      const types = await getTypesService.getTypes();
      setTypes(types);
    } catch (error) {
      console.error("Failed to fetch types:", error.message);
    }
  };
  const fetchStates = async () => {
    try {
      const states = await getStatesService.getStates();
      setStates(states);
    } catch (error) {
      console.error("Failed to fetch states:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;
    //console.log(name, formData.additionalData[name], value);
    if (value !== "") {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        additionalData: {
          ...prevState.additionalData,
          [name]: value,
        },
      }));
      delete formData.additionalData[name];
    }
  };

  const handleToggle = () => {
    setFormData((prevState) => ({
      ...prevState,
      visibility: !prevState.visibility,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await buyerPostService.postDetails(formData);
      if (response.error) {
        notifyDanger(response.error);
      } else {
        notifySuccess("Successful");
        window.location.href = "/main/dashboard";
      }
    } catch (error) {
      console.error("Failed to Post:", error.message);
      notifyDanger("Failed to post");
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setVerifyMessage(false);
    setFormData({
      type: "",
      city: "",
      zip: "",
      state: "",
      currentPremium: "",
      expectedPremium: "",
      startDate: "",
      currentProvider: "",
      userEmail: userData.email,
      userId: userData._id,
      description: "",
      visibility: true,
      bids: {},
      additionalData: {},
    });
  };

  return (
    <div className="pagecontent-buyerpost">
      {sessionExpired && <SessionExpired />}
      {verifyMessage ? (
        <h1 className="buyer-post-heading">Verify and Submit </h1>
      ) : (
        <h1 className="buyer-post-heading">New Listing </h1>
      )}
      <div className="toggle-container">
        <input
          type="checkbox"
          id="visibility-toggle"
          checked={formData.visibility}
          onChange={handleToggle}
          className="toggle-checkbox"
        />
        <label htmlFor="visibility-toggle" className="toggle-label">
          <span className="toggle-button"></span>
        </label>
        <p>{formData.visibility ? "Active" : "Inactive"}</p>
      </div>
      <div className="newlisting-body">
        <div
          className="buyer-post-container"
          style={{
            overflowY: "scroll",
            scrollbarWidth: "thin",
            scrollbarColor: "#888 #e0e0e0",
          }}
        >
          <form onSubmit={handleSubmit} className="buyer-post-form">
            <div className="elements">
              <div className="form-group-newlisting">
                <label htmlFor="type">Type*</label>
                <div className="select-holder-buyerpost">
                  <select
                    name="type"
                    value={formData.type}
                    onChange={(e) =>{handleChange(e);setFormData((prevState) => ({
                      ...prevState,
                      additionalData: {},
                    })); }}
                  >
                    <option value="">Select Type</option>
                    {types.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group-newlisting">
                <label htmlFor="state">State/Province*</label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group-newlisting">
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-newlisting">
                <label htmlFor="zip">ZIP* (XXX XXX)</label>
                <input
                  type="text"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </div>

              {formData.type !== "Mortgage Rate Quote" && (
                <div className="form-group-newlisting">
                  <label htmlFor="currentPremium">
                    Current Premium* (per month)
                  </label>
                  <input
                    type="text"
                    name="currentPremium"
                    value={formData.currentPremium}
                    onChange={handleChange}
                  />
                </div>
              )}
              {formData.type !== "Mortgage Rate Quote" && (
                <div className="form-group-newlisting">
                  <label htmlFor="expectedPremium">Expected Premium</label>
                  <input
                    type="text"
                    name="expectedPremium"
                    value={formData.expectedPremium}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="form-group-newlisting">
                <label htmlFor="startDate">Start Date*</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
              </div>
              {formData.type !== "Mortgage Rate Quote" && (
                <div className="form-group-newlisting">
                  <label htmlFor="currentProvider">Current Provider*</label>
                  <input
                    type="text"
                    name="currentProvider"
                    value={formData.currentProvider}
                    onChange={handleChange}
                  />
                </div>
              )}

              { fields && fields.map((field) => {
                // Check if the field's category includes the form type
                if (field.category? field.category.includes(formData.type) : false) {
                  // If the field does not have a 'depend' property
                  if (field.depend === undefined) {
                    return (
                      <div key={field._id} className="form-group-newlisting">
                        <label htmlFor={field.name}>{field.name === "Driver License Number" ? "Driver License Number *" : field.name}</label>

                        {field.format === "text" && (
                          <input
                            name={field.name}
                            value={formData.additionalData[field.name] || ""}
                            onChange={(e) => handleAdditionalDataChange(e)}
                          />
                        )}

                        {field.format === "dropdown" && (
                          <select
                            name={field.name}
                            value={formData.additionalData[field.name] || ""}
                            onChange={(e) => handleAdditionalDataChange(e)}
                          >
                            <option value="">Select</option>
                            {field.options.split(",").map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        )}

                        {field.format === "date" && (
                          <input
                            type="date"
                            name={field.name}
                            value={formData.additionalData[field.name] || ""}
                            onChange={(e) => handleAdditionalDataChange(e)}
                          />
                        )}
                      </div>
                    );
                  }

                  // If the field has a 'depend' property
                  if (field.depend !== undefined) {
                    const isDependentSatisfied = (field.category? field.category.includes(formData.type) : false) &&
                      field.depend[1] === "X---Any_Value---X"
                        ? formData.additionalData[field.depend[0]] !== "" && formData.additionalData[field.depend[0]] !== undefined
                        : formData.additionalData[field.depend[0]] === field.depend[1] || formData[field.depend[0]] === field.depend[1]; 

                    if (isDependentSatisfied) {
                      return (
                        <div key={field._id} className="form-group-newlisting">
                          <label htmlFor={field.name}>{field.name}</label>
                          {field.format === "text" && (
                            <input
                              name={field.name}
                              value={formData.additionalData[field.name] || ""}
                              onChange={(e) => handleAdditionalDataChange(e)}
                            />
                          )}

                          {field.format === "dropdown" && (
                            <select
                              name={field.name}
                              value={formData.additionalData[field.name] || ""}
                              onChange={(e) => handleAdditionalDataChange(e)}
                            >
                              <option value="">Select</option>
                              {field.options.split(",").map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}

                          {field.format === "date" && (
                            <input
                              type="date"
                              name={field.name}
                              value={formData.additionalData[field.name] || ""}
                              onChange={(e) => handleAdditionalDataChange(e)}
                            />
                          )}
                        </div>
                      );
                    }
                  }
                }
                return null;
              })}

              <div className="form-group-newlisting">
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="buttonholder-newlisting">
              <button
                className="cancel-newlisting"
                onClick={(e) => {
                  handleCancel(e);
                }}
              >
                {" "}
                Cancel
              </button>
              <button type="submit" className="submit-newlisting">
                Submit
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default NewListing;
