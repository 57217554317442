import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const VerifyEmail = () => {
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('token');

            try {
                const response = await fetch(`${API_URL}/verify-email?token=${token}`,
                    {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json',
                        'bmi_api_key': bmi_api_key, 
                      }
                    });
                const data = await response.json();

                
                if (data.error) {
                    setMessage(data.error);
                } else {
                    setMessage(data.message || 'Email verified successfully');
                    setTimeout(() => {
                        navigate("/");
                      }, 5000);
                }
            } catch (error) {
                setMessage('Error verifying email');
            }
        };

        verifyEmail();
    }, [location]);

    return <div>{message}</div>;
};

export default VerifyEmail;
