import React from "react";
import { useNavigate } from "react-router-dom";
import "./About.css";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  const navigate = useNavigate();

  const handleVisitWebsite = () => {
    navigate("/");
  };

  return (
    <div className="about-us-container">
      <Helmet>
      <link rel="canonical" href={window.location.href} />
        <title>Top Car Insurance Quotes & Best Mortgage Rates in Canada</title>
        <meta
          name="description"
          content="Beat My Insurance helps Canadians find top auto insurance quotes and mortgage rates. Compare broker offers and save with a hassle-free process."
        />
        <meta
          property="og:title"
          content="Top Car Insurance Quotes & Best Mortgage Rates in Canada"
        />
        <meta
          property="og:description"
          content="Beat My Insurance helps Canadians find top auto insurance quotes and mortgage rates. Compare broker offers and save with a hassle-free process."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{" "}
        <meta
          name="twitter:title"
          content="Top Car Insurance Quotes & Best Mortgage Rates in Canada"
        />{" "}
        <meta
          name="twitter:description"
          content="Beat My Insurance helps Canadians find top auto insurance quotes and mortgage rates. Compare broker offers and save with a hassle-free process."
        />{" "}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{" "}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Beat My Insurance",
                "item": "https://www.beatmyinsurance.com",
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "About Us",
                "item": "https://www.beatmyinsurance.com/about",
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="about-us-content fade-in">
        <h1 className="about-us-title">About Us</h1>
        <p className="about-us-text">
          At{" "}
          <span className="about-us-highlight" onClick={handleVisitWebsite}>
            Beat My Insurance
          </span>
          , we’re revolutionizing the insurance and mortgage process for the
          buyers and brokers. We simplify your search by allowing you to enter
          your details just once and receive multiple competitive offers,
          eliminating the need for repetitive calls and explanations.
        </p>
        <p className="about-us-text">
          Our platform ensures your privacy, keeping your email and phone number
          confidential until you find a quote you like. Experience the power of
          competitive bidding, where top offers compete to provide you with the
          best value, ensuring you get the most affordable rates.
        </p>
        <p className="about-us-text">
          Say goodbye to endless negotiations and hello to a hassle-free
          experience with Beat My Insurance. We guarantee confidence—if we can’t
          beat your current premium, you’ll have peace of mind knowing you
          already have the best deal.
        </p>
        <p className="about-us-text">
          Envision a future where renewing your insurance or mortgage is just
          one click away. Plus, manage all your insurances effortlessly with our
          unified dashboard.
        </p>
        <p className="about-us-text">
          Join us at Beat My Insurance and make your insurance and mortgage
          process affordable, streamlined, and stress-free.
        </p>
        <button className="about-us-button" onClick={handleVisitWebsite}>
          Visit Our Website
        </button>
      </div>
      <div className="about-us-image-slider">
        <div className="image-container">
          <img
            src="about1.png"
            alt="Cheap Insurance Quotes"
            className="about-us-img slide"
          />
          <img
            src="about2.png"
            alt="Supervisa and cheap car Insurance"
            className="about-us-img slide"
          />
          <img
            src="about3.png"
            alt="Mortgage Rates "
            className="about-us-img slide"
          />
        </div>
      </div>
      <div className="footer-about">
        <span className="copyright">
          Copyright 2024 BMI. All Rights Reserved
        </span>
        <span className="liner-footer">|</span>
        <div className="footer-icons-holder">
          <img
            onClick={() => {
              window.open(
                "https://www.facebook.com/beatmyinsurance/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="facebook.svg"
            alt="facebook"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.instagram.com/beatmyinsurance/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="instagram.svg"
            alt="instagram"
          />
          <img
            onClick={() => {
              window.open(
                "http://www.youtube.com/@BeatMyInsurance",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="youtube.svg"
            alt="youtube"
          />
          <img
            onClick={() => {
              window.open(
                "https://x.com/Beatmyinsurance",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="twitter.svg"
            alt="twitter"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/103720775/admin/feed/posts/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="linkedin.svg"
            alt="linkedin"
          />
        </div>
        <span
          className="terms"
          onClick={() => {
            window.open(
              "https://www.beatmyinsurance.com/terms/",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          Terms of Use | Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default AboutUs;
