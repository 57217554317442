import { json } from "react-router-dom";
import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const sellerService = {
    applySellerVerification: async(formData) =>{
        try{
            const response = await fetch(`${API_URL}/applySeller`,
            {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
            });
            return response.json();
        }catch(error){
            return json({error: "Something went wrong!"});
        }
    }
}

export default sellerService