import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const buyerPostService = () => ({
  postDetails: async (formData) => {
    try {
      const response = await fetch(`${API_URL}/buyerpost`, {
        method: 'POST',
        headers: {
          'bmi_api_key': bmi_api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Failed to post insurance1');
      }
      return response.json();
    } catch (error) {
      throw new Error('Failed to post insurance2');
    }
  },
});

export default buyerPostService();
