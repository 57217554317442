import React from "react";

const Failure = () =>{


    return(
        <div>
            <h2>Sorry!something went wrong!</h2>
            <button onClick={()=>{window.location.href = "https://www.beatmyinsurance.com"}}>Go home</button>
        </div>
    )
};

export default Failure;