import React from "react";
import "./MortgageResult.css";

const MortgageResult = (props) => {
  const { result,tips, handleResultClose } = props;

  const formatValue = (value) => {
    try{
      return isNaN(value) ? "Invalid/ Missing Details!" : value.toFixed(2);
    }catch{
      return "-"
    }
    
  };

  return (
    <>
      <div className="popup-backdrop" onClick={handleResultClose}> </div>
      <div className="calculation-result-mortgage">
        <div className="logo-calculation">
          <img src="/logo.png" alt="beatmyinsurance" />
        </div>

        <p>
          <span>Current Balance: </span>
          <span className="animated-value">{formatValue(result.currentBalance)}</span>
        </p>

        <p>
          <span>Interest Rate (%): </span>
          <span className="animated-value">{formatValue(result.interestRate * 100)}</span>
        </p>

        <p>
          <span>Reinvestment Rate (%): </span>
          <span className="animated-value">{formatValue(result.reinvestRate * 100)}</span>
        </p>

        <p>
          <span>Pending Term (Months): </span>
          <span className="animated-value">{Number(result.pendingTermMonths)}</span>
        </p>

        <p>
          <span>Admin Fee (CAD): </span>
          <span className="animated-value">{formatValue(result.adminFee)}</span>
        </p>

        <p>
          <span>Three Months Penalty (CAD): </span>
          <span className="animated-value">{formatValue(result.threeMonthsPenalty)}</span>
        </p>

        <p>
          <span>IRD (Interest Rate Differential) (CAD): </span>
          <span className="animated-value">{formatValue(result.IRD)}</span>
        </p>

        <p>
          <span>Penalty Applied (CAD): </span>
          <span className="animated-value">{formatValue(result.penalty)}</span>
        </p>

        <p>
          <span>Cashback (CAD): </span>
          <span className="animated-value">{formatValue(result.cashback)}</span>
        </p>

        <p>
          <span>Total Cost (CAD): </span>
          <span className="animated-value">{formatValue(result.totalCost)}</span>
        </p>

        {tips && (
          <p className="advice-section">
            
            <span style={{backgroundColor: tips.includes('It might not make sense to switch right now as the total cost is CAD')? 'rgb(240, 90, 115)' : 'rgb(130, 210, 105)'}}>{tips}</span>
          </p>
        )}

        <button onClick={handleResultClose}>Close</button>
      </div>
    </>
  );
};

export default MortgageResult;
