import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const fetchCities = async () => {
    const response = await fetch(`${API_URL}/fetch-cities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }});
    return response.json();
};

export {fetchCities}