import React, { useState, useEffect, useRef } from "react";
import { getChatsService, sendReplyService, markChatAsRead } from "../services/messageService";
import "./Chats.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Chats = ({ user }) => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [paymentsPop, setPaymentsPop] = useState(false);
  const [userId, setUserId] = useState();
  const [openChatsList, setOpenChatsList] = useState(true);
  const messagesEndRef = useRef(null);

  const fetchChats = async () => {
    if (paymentsPop) {
      return;
    }
  
    try {
      const userData = user;
      if (!userId) {
        setUserId(userData._id);
      }
  
      const response = await getChatsService(userData._id);
  
      if (response.payment) {
        setPaymentsPop(true);
        /*setTimeout(() => {
          window.location.href = "/main/payments";
        }, 5000);*/
      } else {
        // Loop through each chat and set theirName accordingly
        const updatedChats = response.chats.map((chat) => {
          if (chat.nameDetails[0] === userData._id) {
            chat.theirName = chat.nameDetails[3]; 
          } else if (chat.nameDetails[2]=== userData._id){
            chat.theirName = chat.nameDetails[1]; 
          }else {
            chat.theirName = "unknown"
          }
          return chat; // Return the updated chat object
        });
  
        setChats(updatedChats);
  
        if (selectedChat) {
          const updatedChat = updatedChats.find(
            (chat) => chat._id === selectedChat._id
          );
  
          if (
            updatedChat &&
            updatedChat.allMessages.length !== selectedChat.allMessages.length
          ) {
            // Update selected chat if new messages are found
            setSelectedChat(null);
            setTimeout(() => {
              setSelectedChat(updatedChat);
            }, 1);
          }
        }
      }
    } catch (error) {
      console.log(error, "Unable to fetch chats");
    }
  };
  

  useEffect(() => {
    fetchChats();
    const intervalId = setInterval(() => {
      fetchChats();
    }, 9000);

    return () => clearInterval(intervalId);
  }, [paymentsPop]);

  const handleChatClick = async(chat) => {
    setSelectedChat(chat);
    setOpenChatsList(false);
    try{
      await markChatAsRead(chat.chatKey, user._id);
    }catch{
      console.error('Unable to mark notification as read');
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!messageInput.trim()) return;

    try {
      const response = await sendReplyService({
        chatId: selectedChat._id,
        from: user._id,
        message: messageInput,
      });
  
      if(response.payment){
        setPaymentsPop(true);
        notifyDanger(response.payment);
        return;
      }
      if (response.message) {
        const updatedChat = { ...selectedChat };
        updatedChat.allMessages.push([
          user._id,
          messageInput,
          new Date(),
          false,
        ]);
        setSelectedChat(null);
        setTimeout(() => {
          setSelectedChat(updatedChat)
        }, 100);
        fetchChats();
        setMessageInput("");
      } else {
        notifyDanger(response.error || "something went wrong!");
      }
    } catch (error) {
      console.error("Failed to send message", error);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [selectedChat?.allMessages]);

  const formatTime = (time) => {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  return (
    <div className="chats-page-holder">
      <ToastContainer />
      <h1 className="chats-main-heading">Chats</h1>
      <button className="select-chat-btn" onClick={() => setOpenChatsList(!openChatsList)}>{openChatsList? <span>Close </span> : <span>Select chat</span>}</button>
      <div className="chats-page-content">
        {paymentsPop && (
          <div className="payments-pop-chats">
            <p>Oops! You don't have an active plan to send more messages!</p>
            <p>Upgrade your plan to chat more!</p>
            <button className="chats-payments-redirect" onClick={() => window.location.href = "/main/payments"}>Upgrade Plan</button>
          </div>
        )}
        <div className="chat-list">
          <div className="chat-list-heading"><p>Messages</p></div>
          <div className="chat-list-data">
            {chats.length > 0 ? (
              chats.map((chat) => (
                <div
                  key={chat._id}
                  className={`chat-item ${
                    selectedChat && selectedChat._id === chat._id ? "active" : ""
                  }`}
                  onClick={() => handleChatClick(chat)}
                >
                  <p>{chat.theirName}</p>
                  <small>
                    Created at: {new Date(chat.createdAt).toLocaleString()}
                  </small>
                </div>
              ))
            ) : (
              <p className="no-chats-message">No chats available</p>
            )}
          </div>
        </div>
      {/* for tab & mobile slider */}
        <div className={openChatsList? "chat-list-slider-active" : "chat-list-slider"}>
          <div className="chat-list-heading"><p>Chats</p></div>
          <div className="chat-list-data">
            {chats.length > 0 ? (
              chats.map((chat) => (
                <div
                  key={chat._id}
                  className={`chat-item ${
                    selectedChat && selectedChat._id === chat._id ? "active" : ""
                  }`}
                  onClick={() => handleChatClick(chat)}
                >
                  <p>{chat.theirName}</p>
                  <small>
                    Created at: {new Date(chat.createdAt).toLocaleString()}
                  </small>
                </div>
              ))
            ) : (
              <p className="no-chats-message">No chats available</p>
            )}
          </div>
        </div>

        <div className="chat-messages">
          {selectedChat ? (
            <>
      
              <div className="messages-container">
                {selectedChat.allMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`message-item ${
                      message[0] === user._id ? "my-message" : "their-message"
                    }`}
                  >
                      <div className="message-details">
                        <img className={message[0] === user._id ? "me-icon" : "you-icon"} src={message[0] === user._id ? "/metest.png" : "/profile.png"} alt="sender"/> 
                        <p className="message-text">{message[1]}{" "}</p>
                      </div>
                      <span className="message-time">
                        {formatTime(message[2])}
                      </span>
                    
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>

              <form className="message-input-container" onSubmit={handleSendMessage}>
                <input
                  type="text"
                  value={messageInput}
                  autoFocus
                  onChange={(e) => setMessageInput(e.target.value)}
                  placeholder="Type your message...(max 160 characters)"
                  className="message-input"
                />
                <button
                  onClick={(e) => handleSendMessage(e)}
                  className="send-button"
                  disabled={!messageInput.trim()}
                >
                  Send
                </button>
              </form>
            </>
          ) : (
            <p className="select-chat-message">Select a chat to view messages</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chats;
