import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const editVisibility = async (data) =>{
    try{
      const response = await fetch(`${API_URL}/edit-visibility`, {
        method: 'POST',
        headers: {
          'bmi_api_key': bmi_api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error('Failed to update visibility');
      }
  
      return await response.json();
    }catch(error){
      console.log(error);
    }
  };

export {editVisibility}