import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const getPostsService = {
  getPosts: async (userId) => {
    try {
      const response = await fetch(`${API_URL}/posts/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'bmi_api_key': bmi_api_key, 
          }
        });
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      return response.json();
    } catch (error) {
      throw new Error('Failed to fetch posts');
    }
  },
};

export default getPostsService;
