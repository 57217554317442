import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const changePasswordMail = async (formData) => {
    const response = await fetch(`${API_URL}/change-password`, { 
        method: "POST",
        headers: {
            'bmi_api_key': bmi_api_key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
    });

    return response.json();
};

const resetPassword = async (formdata) => {
    const response = await fetch(`${API_URL}/reset-password`, { 
        method: "POST",
        headers: {
            'bmi_api_key': bmi_api_key,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(formdata)
    });

    return response.json();
};

export {changePasswordMail, resetPassword};
