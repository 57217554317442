import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const getMyPostsService = {
  getMyPosts: async (userEmail) => {
    try {
      const response = await fetch(`${API_URL}/myposts?userEmail=${encodeURIComponent(userEmail)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'bmi_api_key': bmi_api_key, 
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user posts');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch user posts: ' + error.message);
    }
  },
};

export default getMyPostsService;
