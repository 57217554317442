import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Login.css";
import userService from "../services/userService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "../services/passwordService";
import MobileVerification from "./MobileVerification";

const Login = (login) => {
  const location = useLocation();
  const [newUser, setNewUser] = useState(login);
  const [email, setEmail] = useState();
  const [forgotPassword, setForgotPassword] = useState();
  const [registerCredentials, setRegisterCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });
  const [termsAgreed, setTermsAgreed] = useState(false);

  const navigate = useNavigate();

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };
  useEffect(() => {
    if (location.state?.newUser !== undefined) {
      setNewUser(location.state.newUser);
    }
  }, [location.state]);
  useEffect(() => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged === "true") {
      navigate("/main");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (newUser) {
      setRegisterCredentials({ ...registerCredentials, [name]: value });
    } else if (!newUser) {
      setLoginCredentials({ ...loginCredentials, [name]: value });
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password } = registerCredentials;

    if (!termsAgreed) {
      notifyDanger("Please accept Terms&Conditions to proceed");
      return;
    }

    if (!firstName || !email || !password) {
      notifyDanger("All fields are required");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      notifyDanger("Invalid email format");
      return;
    }

    if (password.length < 8) {
      notifyDanger("Password must be at least 8 characters long");
      return;
    }

    try {
      const response = await userService.register(registerCredentials);
      if (response.message) {
        notifySuccess(response.message);
        setRegisterCredentials({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
        });
        setTermsAgreed(false);
        
      } else {
        notifyDanger(response.error || "Registration failed");
      }
    } catch (error) {
      notifyDanger("Registration failed");
      console.error(error);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await userService.login(loginCredentials);

      //const user = await userService.getUser(response.message._id);
      //console.log(user);
      if (response.blacklist) {
        notifyDanger(response.blacklist);
      } else if (response.token) {
        // Assuming response.token is the token you want to store
        document.cookie = `BMI-token=${response.token}; path=/; max-age=86400; secure; SameSite=Strict`;
        localStorage.setItem("isLogged", "true");
        notifySuccess("Login Successful");

        navigate("/main");
      } else {
        notifyDanger(response.error || "Something went wrong");
      }
    } catch (error) {
      notifyDanger("Login failed");
      console.error(error);
    }
  };
  const handleForgotPassword = async () => {
    try {
      if (!/\S+@\S+\.\S+/.test(email)) {
        notifyDanger("Invalid email format");
        return;
      }
      const response = await resetPassword({ email: email });
      if (response.message) {
        notifySuccess(response.message);
      } else {
        notifyDanger(response.error || "sorry! something went wrong" + "kkk");
      }
    } catch (error) {
      notifyDanger(error);
    }
  };

  return (
    <div className="login-page">
      <Helmet>
      <link rel="canonical" href={window.location.href} />
        <title>Login | Connect Insurance Buyers and Sellers in Canada</title>
        <meta
          name="description"
          content="Log in to Beat My Insurance to list your insurance needs as a buyer or get auto insurance leads as a broker. Manage your account with ease."
        />
        <meta
          property="og:title"
          content="Login | Connect Insurance Buyers and Sellers in Canada"
        />
        <meta
          property="og:description"
          content="Log in to Beat My Insurance to list your insurance needs as a buyer or get auto insurance leads as a broker. Manage your account with ease."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{" "}
        <meta
          name="twitter:title"
          content="Login | Connect Insurance Buyers and Sellers in Canada"
        />{" "}
        <meta
          name="twitter:description"
          content="Log in to Beat My Insurance to list your insurance needs as a buyer or get auto insurance leads as a broker. Manage your account with ease."
        />{" "}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{" "}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "About Us",
                item: "https://www.beatmyinsurance.com/about",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Login",
                item: "https://www.beatmyinsurance.com/login",
              },
            ],
          })}
        </script>
      </Helmet>
      {newUser && (
        <div className="register-container">
          <img
            src="cross.svg"
            alt="close"
            className="close-register"
            onClick={() => {
              navigate("/home");
            }}
          />

          <form>
            <div className="register-holder-text">
              <h1 id="main-text">
                <b>Create an account</b>
              </h1>
              <p id="side-text">Let's get started!</p>
            </div>
            <div className="inputs-holder">
              <div id="input-container">
                <div className="labels">First Name</div>
                <input
                  id="firstName"
                  onChange={handleChange}
                  type="text"
                  name="firstName"
                  value={registerCredentials.firstName}
                  placeholder="Enter First Name"
                  autoFocus
                />
              </div>
              <div id="input-container">
                <div className="labels">Last Name</div>
                <input
                  id="lastName"
                  onChange={handleChange}
                  type="text"
                  name="lastName"
                  value={registerCredentials.lastName}
                  placeholder="Enter Last Name"
                />
              </div>
              <div id="input-container">
                <div className="labels">Email</div>
                <input
                  id="email"
                  onChange={handleChange}
                  type="text"
                  value={registerCredentials.email}
                  name="email"
                  placeholder="Enter Email"
                />
              </div>
              <div id="input-container">
                <div className="labels">Password</div>
                <input
                  id="password"
                  onChange={handleChange}
                  type="password"
                  value={registerCredentials.password}
                  name="password"
                  placeholder="Enter Password"
                />
              </div>

              <div className="terms-agree">
                <input
                  type="radio"
                  onClick={() => setTermsAgreed(!termsAgreed)}
                  checked={termsAgreed}
                ></input>
                <span>
                  {" "}
                  I agree to the{" "}
                  <a href="https://www.beatmyinsurance.com/terms/">
                    terms & conditions
                  </a>
                </span>
              </div>

              <button
                className="register-btn"
                type="submit"
                onClick={handleRegisterSubmit}
              >
                Register
              </button>
              <ToastContainer />
              <div className="social-icons">
                <a
                  onClick={() => {
                    termsAgreed
                      ? (window.location.href =
                          "http://www.beatmyinsurance.com/api/auth/google")
                      : notifyDanger(
                          "Please accept Term&Conditions to proceed"
                        );
                  }}
                >
                  <img src="google-icon.png" alt="Google Icon" />
                </a>
                <span
                  onClick={() => {
                    termsAgreed
                      ? (window.location.href =
                          "http://www.beatmyinsurance.com/api/auth/google")
                      : notifyDanger(
                          "Please accept Term&Conditions to proceed"
                        );
                  }}
                >
                  Sign in with Google
                </span>
              </div>
              <div className="have-account">
                <p>Already have an account?</p>
                <a
                  onClick={() => {
                    setNewUser(!newUser);
                  }}
                >
                  Login
                </a>
              </div>
            </div>
          </form>
        </div>
      )}

      {!newUser && (
        <div className="login-container">
          <img
            src="cross.svg"
            alt="close"
            className="close-register"
            onClick={() => {
              navigate("/home");
            }}
          />

          <form>
            <div className="register-holder-text">
              <h1 id="main-text">
                <b>Login</b>
              </h1>
              <p id="side-text">Welcome back!</p>
            </div>
            <div className="inputs-holder">
              <div id="input-container">
                <div className="labels">Email</div>
                <input
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  onChange={handleChange}
                  autoFocus
                />
              </div>
              <div id="input-container">
                <div className="labels">Password</div>
                <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  onChange={handleChange}
                />
              </div>

              <button
                className="login-submit"
                type="submit"
                onClick={handleLoginSubmit}
              >
                Login
              </button>
              <p
                className="button-forgot-password"
                onClick={() => setForgotPassword(true)}
              >
                Forgot Password?
              </p>
              <ToastContainer newestOnTop={true} />
              <div className="social-icons">
                <a href="http://www.beatmyinsurance.com/api/auth/google">
                  <img src="google-icon.png" alt="Google Icon" />
                </a>
                <span
                  onClick={() =>
                    (window.location.href =
                      "http://www.beatmyinsurance.com/api/auth/google")
                  }
                >
                  Sign in with Google
                </span>
              </div>
              <div className="have-account">
                <p>Don't have an account?</p>
                <a
                  onClick={() => {
                    setNewUser(!newUser);
                  }}
                >
                  Register
                </a>
              </div>
            </div>
          </form>
          {forgotPassword && (
            <div className="forgot-password">
              <img
                src="cross.svg"
                alt="close"
                onClick={() => {
                  setForgotPassword(false);
                  setEmail("");
                }}
              />

              <label>Enter your registered email</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email here"
              />
              <button onClick={() => handleForgotPassword()}>Send Mail</button>
            </div>
          )}
        </div>
      )}
      <div className="footer">
        <span className="copyright">
          Copyright 2024 BMI. All Rights Reserved
        </span>
        <span className="liner-footer">|</span>
        <div className="footer-icons-holder">
          <img
            onClick={() => {
              window.open(
                "https://www.facebook.com/beatmyinsurance/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="facebook.svg"
            alt="facebook"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.instagram.com/beatmyinsurance/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="instagram.svg"
            alt="instagram"
          />
          <img
            onClick={() => {
              window.open(
                "http://www.youtube.com/@BeatMyInsurance",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="youtube.svg"
            alt="youtube"
          />
          <img
            onClick={() => {
              window.open(
                "https://x.com/Beatmyinsurance",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="twitter.svg"
            alt="twitter"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/103720775/admin/feed/posts/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="linkedin.svg"
            alt="linkedin"
          />
        </div>
        <span
          className="terms"
          onClick={() => {
            window.open(
              "https://www.beatmyinsurance.com/terms/",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          Terms of Use | Privacy Policy
        </span>
      </div>
    </div>
  );
};

export default Login;
