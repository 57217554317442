import {React,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Buyers.css';

const Buyers = ()=>{
    return(
        <div className='buyers-b-page'>
            <img className='sellers-b-logo' src='/logo.png' alt='logo'/>
            <p className='buyers-b-main-head'><span>Challenge providers</span> to beat your premium and let the best quote win!</p>
            <p className='buyers-b-side-head'>A fresh, private, and secure way to score unbeatable rates</p>
            <button className='new-b-listings' onClick={()=> window.location.href = "https://www.beatmyinsurance.com/buy-insurance"}>Post New Listing</button>
            <img className='sellers-b-content' onClick={()=> window.location.href = "https://www.beatmyinsurance.com/buy-insurance"} src='/buyersContent.png' alt='search buyers'/>
            <img className='sellers-b-content-mobile' onClick={()=> window.location.href = "https://www.beatmyinsurance.com/buy-insurance"} src='/buyersContentMobile.png' alt='search buyers'/>
            <img className='buyers-b-comment1' src='/sellersComment1.png' alt='game changer'/>
            <img className='buyers-b-comment2' src='/buyersComment2.png' alt='200 plus posts'/>
        </div>
    )
}
export default Buyers;