import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import updateUserProfile from "../services/userProfileService";
import sellerService from "../services/sellersService";
import userService from "../services/userService";
import uploadImage from "../services/imageUpload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionExpired from "./SessionExpired";

const Profile = ({ user }) => {
  const [userData, setUserData] = useState(null);
  const [image, setImage] = useState("/profile.png");
  const [imageEdit, setImageEdit] = useState(false);
  const [editDetails, seEditDetails] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [verifySellerPop, setVerifySellerPop] = useState(false);
  const [licenseNumber, setLicenseNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [licensedSince, setLicensedSince] = useState();
  const navigate = useNavigate();

  const fetchUserProfile = () => {
    setUserData(user);
    setImage(user.image !== "" ? user.image : "/profile.png");
  };
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

  const refreshLocal = async () => {
    const newuser = await userService.getUser(getCookie('BMI-token'));
    if (newuser.user) {
      setUserData(newuser.user);
      setImageEdit(false);
      seEditDetails(false);
      setImageFile();
      if (newuser.user.image !== ""){
        setImage(newuser.user.image);
      }
      else{
        setImage("/profile.png")
      }
    }
    else{
      setSessionExpired(true);
    }
    window.location.reload();
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const handleSaveImage = async () => {
    try {
      const response = await uploadImage(
        userData._id,
        userData.image,
        imageFile
      );
      if (response.message) {
        notifySuccess("successfully uploaded");
        refreshLocal();
      } else {
        notifyDanger("Failed to upload");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      notifyDanger("Error uploading image");
    }
  };

  const handleDetailsSave = async (e) => {
    e.preventDefault();
    try {
      const result = await updateUserProfile(userData._id, userData);

      if (result.error) {
        notifyDanger(result.error);
        return;
      }
      notifySuccess("Profile updated successfully");
      refreshLocal();
    } catch (error) {
      notifyDanger(error);
    }
  };

  const handleCancel = () => {
    fetchUserProfile();
    setImageEdit(false);
    seEditDetails(false);
    fetchUserProfile();
  };

  const handleFirstNameChange = (e) => {
    setUserData({ ...userData, firstName: e.target.value });
  };
  const handleLastNameChange = (e) => {
    setUserData({ ...userData, lastName: e.target.value });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" }; // Customize options as needed
    return new Date(dateString).toLocaleDateString("en-CA", options); // 'en-CA' locale for Canada format
  };

  const handleVerifyClick = () =>{
    setVerifySellerPop(!verifySellerPop);
  }

  const verifySellerSubmit = async(e)=>{
    e.preventDefault();
    const userDetails = user;
    const formData = {
      licenseNumber:licenseNumber,
      companyName:companyName,
      licensedSince:licensedSince,
      userId : userDetails._id
    }
    const response = await sellerService.applySellerVerification(formData);
    if (response.message){
      notifySuccess(response.message);
      setLicenseNumber("");
      setCompanyName("");
      setLicensedSince();
      setVerifySellerPop(false);
    }
    else {
      notifyDanger(response.error || "Something went wrong!");
    }
  };

  const handleSellerDetailsCancel = () =>{
    setLicenseNumber("");
    setCompanyName("");
    setLicensedSince();
    setVerifySellerPop(false);
  }

  return (
    <div className="profile-page">
      <ToastContainer />
      {sessionExpired && (
        <SessionExpired/>
      )}
      {userData ? (
        <>
          <h1 className="profile-heading">User Profile</h1>
          <div className="page-content-profile">
            <div className="header-container-profile">
              <div className="intro-profile-container">
                <div
                  className="intro-profile-img"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => setImageEdit(true)}
                ></div>
                <div className="intro-profile-content">
                  <p className="intro-profile-head">{userData.name}</p>
                </div>
              </div>
              {imageEdit && (
                <div className="image-edit-profile">
                  <input
                    name="image"
                    type="file"
                    accept=".jpg,.jpeg,image/jpeg"
                    onChange={handleImageChange}
                  />
                  <button
                    className="image-save-profile"
                    onClick={() => handleSaveImage()}
                  >
                    Save
                  </button>
                  <button
                    className="image-cancel-profile"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                </div>
              )}
              <button
                className="profile-header-button"
                onClick={() => setImageEdit(true)}
              >
                <img src="/edittest.png" alt="edit" />
                <p>Edit</p>
              </button>
            </div>
            <div className="profile-details-container">
              <button
                className="profile-content-edit"
                onClick={() => seEditDetails(true)}
              >
                <img src="/edittest.png" alt="edit" />
                <p>Edit</p>
              </button>
              <div className="profile-first-name">
                <p className="profile-name-head">First Name</p>
                <p className="profile-name-content">{userData.firstName || "-"}</p>
              </div>
              <div className="profile-last-name">
                <p className="profile-lastname-head">Last Name</p>
                <p className="profile-lastname-content">{userData.lastName || "-"}</p>
              </div>
              <div className="profile-email">
                <p className="profile-email-head">Email Address</p>
                <p className="profile-email-content">{userData.email}</p>
              </div>
              <div className="profile-mobile">
                <p className="profile-mobile-head">Phone Number</p>
                <p className="profile-mobile-content">{userData.mobileVerified ? userData.mobile : (<button className="mobile-verify-btn-profile" onClick={()=>navigate(`/mobile-verification/${userData._id}`)}>Verify</button>)}</p>
              </div>
              <div className="profile-bio">
                <p className="profile-bio-head">Bio</p>
                <p className="profile-bio-content">{userData.bio}</p>
              </div>
              <div className="profile-member">
                <p className="profile-member-head">Member Since</p>
                <p className="profile-member-content">
                  {formatDate(userData.joiningDate)}
                </p>
              </div>
              <div className="profile-seller-verify">
                {user.verifiedSeller ? (
                  <div className="verified-seller">
                    <h2>Verified Seller</h2>
                    <img src="/verified.png" alt="verified"/>
                  </div>
                )
              :
              (<button onClick={() => handleVerifyClick()} >Verify as a Seller</button>)}
              </div>
            </div>
            {verifySellerPop && (
              <form className="verify-seller-pop" onSubmit={(e) => verifySellerSubmit(e)}>
                <label htmlFor="licenseNumber">Agent Licence #:</label>
                <input type="text" name="licenseNumber" value={licenseNumber} autoFocus onChange={(e) => setLicenseNumber(e.target.value)} placeholder="Enter license number"/>
                <label htmlFor="companyName">Company Name:</label>
                <input type="text" name="companyName" value={companyName}  onChange={(e) => setCompanyName(e.target.value)} placeholder="Enter company name"/>
                <label htmlFor="licensedSince">Licensed Since?</label>
                <input type="date" name="licensedSince" value={licensedSince}  onChange={(e) => setLicensedSince(e.target.value)} />
                <button className="seller-details-cancel" onClick={()=> handleSellerDetailsCancel()} >Cancel</button>
                <button className="seller-details-submit" type="submit" onClick={(e)=> verifySellerSubmit(e)} >Submit</button>
              </form>
            )}

            {editDetails && (
              <form
                className="edit-details-profile"
                onSubmit={(e) => handleDetailsSave(e)}
              >
                <label htmlFor="firstName">First Name*</label>
                <input
                  type="text"
                  className="name-edit-profile"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleFirstNameChange}
                />
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="name-edit-profile"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleLastNameChange}
                />
                <label htmlFor="mobile">Phone Number*</label>
                {/*<input
                  type="tel"
                  className="mobile-edit-profile"
                  name="mobile"
                  value={userData.mobile}
                  onChange={(e) => {
                    setUserData({ ...userData, mobile: e.target.value });
                  }}
                />*/}
                <button className="mobile-edit-profile" onClick={()=>navigate(`/mobile-verification/${userData._id}`)}>Change Number</button>
                <label htmlFor="bio">Bio</label>
                <input
                  type="text"
                  className="bio-edit-profile"
                  name="bio"
                  value={userData.bio}
                  onChange={(e) => {
                    setUserData({ ...userData, bio: e.target.value });
                  }}
                />

                <button
                  className="profile-changes-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="profile-changes-save"
                  onClick={(e) => handleDetailsSave(e)}
                >
                  Save
                </button>
              </form>
            )}
          </div>
        </>
      ) : (
        <img src="/loading.gif" alt="loading" />
      )}
    </div>
  );
};

export default Profile;
