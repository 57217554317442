import { Helmet } from 'react-helmet';

const DynamicMeta = ({ title, description, keywords }) => (
  <Helmet>
    <title>{title}</title>
    <meta property='description' content={description}/>
    <meta property='twitter:title' content={title}/>
    <meta property='twitter:description' content={description}/>
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property='keywords' content={keywords} />
  </Helmet>
);

export default DynamicMeta;
