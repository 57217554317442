import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

let details;

const bidService = {
  submitBid: async (postId, userId, userEmail,userName, bidAmount, creatorid, userSubscription, session) => {
    try {
      const response = await fetch(`${API_URL}/posts/${postId}`, {
        method: 'PATCH',
        headers: {
          'bmi_api_key': bmi_api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: userEmail,
          userName: userName,
          bidAmount: bidAmount,
          creatorid: creatorid,
          userId: userId,
          userSubscription: userSubscription,
          session: session,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit bid');
      }

      const data = await response.json();
      details = data;
      return data;
    } catch (error) {
      throw new Error('Failed to submit bid: ' + error.message);
    }
  },
  remainingBids: async (postId, userEmail) => {
    try {
      const response = await fetch(`${API_URL}/remainingBids/${postId}`, {
        method: 'POST',
        headers: {
          'bmi_api_key': bmi_api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: userEmail,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit bid');
      }
      const data = await response.json();
      details = data;
      return data;
    } catch (error) {
      throw new Error('Failed to submit bid: ' + error.message);
    }
  },

};

export default bidService;
