import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const positionsService = {
    getPositions: async() =>{
      try {
        const response = await fetch(`${API_URL}/positions`); 
        return response.json(); 
      } catch (error) {
        console.error('Failed to fetch positions:', error.message);
        throw new Error('Failed to fetch positions');
      }
    },
  };
  
  export default positionsService;
  