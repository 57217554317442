import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom"; 
import getMyPostsService from "../services/getMyPostsService";
import NotificationService from "../services/notificationService"; 
import EditPost from "./EditPost";
import SessionExpired from "./SessionExpired";

const Dashboard = ({ user }) => {
  const [postId, setPostId] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("/profile.png");
  const [ sessionExpired, setSessionExpired] = useState(false);
  const editPostRef = useRef(null);

  useEffect(() => {
    setUserName(user.name);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    if (user.image.trim() !== ""){
      setUserImage(user.image);
    }
    fetchUserPosts();
    fetchNotificationCount(); 
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editPostRef.current && !editPostRef.current.contains(event.target)) {
        setPostId(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchUserPosts = async () => {
    try {
      const userEmail = user.email;
      const response = await getMyPostsService.getMyPosts(userEmail);
  
      setUserPosts(response.reverse());
    } catch (error) {
      console.error("Failed to fetch user posts:", error.message);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const userId = user._id;
      const data = await NotificationService.getNotificationsForUser(userId);
      const unreadCount = data.filter(
        (notification) => !notification.read
      ).length; 
    } catch (error) {
      console.error("Failed to fetch notification count:", error.message);
    }
  };

  const handlePostClick = (post_id)  => { 
    if (postId) {
      setPostId(false);
    } else {
      setPostId(post_id);
    }
  };

  const getTimeSinceUpload = (uploadTime) => {
    const now = new Date();
    const uploadDate = new Date(uploadTime);
    const diffMs = now - uploadDate;
    const diffMins = Math.floor(diffMs / 60000);
    const diffHrs = Math.floor(diffMs / 3600000);
    const diffDays = Math.floor(diffMs / 86400000);

    if (diffMins < 60) {
      if(diffMins < 0){
        return `just now`;
      }
      if(diffMins === 1){
        return `${diffMins} minute ago`;
      } 
      return `${diffMins} minutes ago`;
    } else if (diffHrs < 24) {
      if(diffHrs === 1){
        return `${diffHrs} hour ago`;
      }
      return `${diffHrs} hours ago`;
    } else {
      if(diffDays === 1){
        return `${diffDays} day ago`;
      } 
      return `${diffDays} days ago`;
    }
  };


  return (
    <div id="pagecontent-dash">
      {sessionExpired && (
        <SessionExpired/>
      )}
      <div className="dashboard-head">
        <div className="profile-holder-dashboard" style={{backgroundImage : `url(${userImage})`, backgroundSize : 'cover', backgroundPosition : 'center', backgroundRepeat : 'no-repeat'}}>
          
        </div>
        <div className="welcome-holder-dashboard">
          <p id="main-text-dashboard">
            Hi, {firstName.charAt(0).toUpperCase() + firstName.slice(1)+ " " + lastName.charAt(0).toUpperCase() + lastName.slice(1)}!
          </p>
          <h2 id="side-text-dashboard">Welcome back!</h2>
        </div>
      </div>
      <div className="dashboard-body">
        <h1 className="dashboard-heading">My Posts</h1>
        <div className="user-posts">
          {userPosts.map((post) => (
            <div
              key={post._id}
              className="post-card"
              onClick={() =>handlePostClick(post._id)}
            >
              <img src="/dot.png" alt="dot"/>
              <p className="post-card-type">{post.type}</p>
              
              <p className="post-card-upload">
                {getTimeSinceUpload(post.uploadTime)}
              </p>
              <p className="post-card-startdate">Start Date: {post.startDate}</p>
              <button onClick={() =>handlePostClick(post._id)}>View</button>
            </div>
          ))}
          {userPosts.length ===0 && (
            <>
            <img className="empty-dashboard" src="/empty.png" alt="empty"/>
            <button className="start-posting" onClick={()=> {window.location.href= '/main/buyer-post'}}>Start Posting</button>
            </>
          ) }
        </div>
      </div>

      {postId && (
        <div ref={editPostRef} className="editpost-holder-dashboard">
          <EditPost postId={postId} userData = {user}/>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
