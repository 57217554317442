import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const getStatesService = {
    async getStates() {
      try {
        const response = await fetch(`${API_URL}/states`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'bmi_api_key': bmi_api_key, 
            }
          }); 
        const data = await response.json();
        return data.states; 
      } catch (error) {
        console.error('Failed to fetch states:', error.message);
        throw new Error('Failed to fetch states');
      }
    },
  };
  
  export default getStatesService;
  