import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import getPostsService from "../services/getPostsService";
import getTypesService from "../services/getTypesService";
import postDetailsProService from "../services/postDetailsProAdmin";
import postDetailsService from "../services/postDetailsAdmin";
import restrictedFieldsService from "../services/restrictedFieldsService";
import bidService from "../services/bidService";
import "./SellInsurance.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import SessionExpired from "./SessionExpired";

const SellInsurance = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [filter, setFilter] = useState({
    type: "",
    city: "",
    state: "",
    currentPremium: "",
    expectedPremium: "",
  });
  const [types, setTypes] = useState([]);
  const [bidAmount, setBidAmount] = useState("");
  const [showBidPopup, setShowBidPopup] = useState(false);
  const [fields, setFields] = useState([]);
  const bidPopupRef = useRef(null);
  const postDetailsRef = useRef(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [remainingBids, setRemainingBids] = useState();
  const [postId, setPostId] = useState();
  const [restrictedFields, setRestrictedFields] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchFields = async () => {
    try {
      const fields1 = await postDetailsService.getpostDetails();
      const fields2 = await postDetailsProService.getpostDetailsPro();
      const fields = { ...fields1, ...fields2 };
      setFields(fields);
    } catch (error) {
      console.error("Failed to fetch fields:", error.message);
    }
  };

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const fetchRestrictedFields = async() =>{
    try{
      const response = await restrictedFieldsService.getFields();
      console.log(response.fields);
      if(!response.error){
        const filteredFields = [];
        response.fields.forEach(field => {
          filteredFields.push(field.name);
        });
        setRestrictedFields(filteredFields || []);
      }
    }catch(error){
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPosts();
    fetchTypes();
    fetchFields();
    fetchRestrictedFields();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [filter, posts]);

  useEffect(() => {
    // Add event listener to detect clicks outside the bid popup
    const handleClickOutside = (event) => {
      if (bidPopupRef.current && !bidPopupRef.current.contains(event.target)) {
        setShowBidPopup(false);
        setBidAmount("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Add event listener to detect clicks outside the bid popup
    const handleClickOutsidePost = (event) => {
      if (
        postDetailsRef.current &&
        !postDetailsRef.current.contains(event.target)
      ) {
        setSelectedPost(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutsidePost);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePost);
    };
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await getPostsService.getPosts(null);
      const realposts = response.filter((post) => post.userId !== null);
      setPosts(realposts.reverse());
      if (id) {
        setPostId(id);
        const allPosts = [...realposts];
        const sharedPost = allPosts.filter((post) => post._id.includes(id));
        setSelectedPost(sharedPost[0]);
      }
    } catch (error) {
      console.error("Failed to fetch posts:", error.message);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await getTypesService.getTypes();
      setTypes(response);
    } catch (error) {
      console.error("Failed to fetch types:", error.message);
    }
  };

  const applyFilter = () => {
    let filtered = [...posts];
    if (filter.type) {
      filtered = filtered.filter((post) =>
        post.type.toLowerCase().includes(filter.type.toLowerCase())
      );
    }
    if (filter.city) {
      filtered = filtered.filter((post) =>
        post.city.toLowerCase().includes(filter.city.toLowerCase())
      );
    }
    if (filter.state) {
      filtered = filtered.filter((post) =>
        post.state.toLowerCase().includes(filter.state.toLowerCase())
      );
    }
    if (filter.currentPremium) {
      filtered = filtered.filter((post) =>
        post.currentPremium
          .toLowerCase()
          .includes(filter.currentPremium.toLowerCase())
      );
    }
    if (filter.expectedPremium) {
      filtered = filtered.filter((post) =>
        post.expectedPremium
          .toLowerCase()
          .includes(filter.expectedPremium.toLowerCase())
      );
    }

    setFilteredPosts(filtered);
  };

  const handleRowClick = (post) => {
    if (selectedPost) {
      setSelectedPost(false);
      setRemainingBids("-");
    } else {
      setSelectedPost(post);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleBidClick = async () => {
    localStorage.setItem("BMI-selectedPost", JSON.stringify(selectedPost));
    navigate("/login", { state: { newUser: true } });
    /*
    setShowBidPopup((prev) => !prev);
    const response = await bidService.remainingBids(
      selectedPost._id,
      user.email
    );
    console.log(response);
    if (response.bids) {
      setRemainingBids(response.bids);
    } else if (response.error) {
      setRemainingBids("-");
      notifyDanger(response.error);
    } else {
      setRemainingBids(0);
    } */
  };

  <button className="share-button" onClick={handleBidClick}>
    Share
  </button>;

  const handleShareClick = () => {
    const shareData = {
      title: "Check out this post",
      text: "BeatMyInsurance!",
      url: `/sell-insurance/${selectedPost._id}`,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Post shared successfully"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Web Share API not supported in this browser");
    }
  };

  const formatLabel = (str) => {
    return str
      .replace(/([A-Z])/g, " $1") // Insert a space before capital letters
      .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize the first letter
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const handleBidSubmit = async (e) => {
    e.preventDefault();
    /*try {
      const session = getCookie("BMI-token");
      // Call bid service to submit the bid
      const userData = user;
      // Extract user email from userData

      const userId = userData._id;
      const userEmail = userData.email;
      const userName = userData.name;
      const creatorid = selectedPost.userId;
      const userSubscription = userData.subscription;

      const response = await bidService.submitBid(
        selectedPost._id,
        userId,
        userEmail,
        userName,
        bidAmount,
        creatorid,
        userSubscription,
        session
      );
      setShowBidPopup(false);

      if (response.expired) {
        setSessionExpired(true);
        notifyDanger("Session expired");
        return;
      }
      if (response.error) {
        notifyDanger(response.error);
      } else if (response.payment) {
        notifyDanger(response.payment);
        window.location.href = "https://www.beatmyinsurance.com/main/payments"
      } else {
        notifySuccess(response.message);
        handleBidClick();
      }
      setBidAmount("");
    } catch (error) {
      notifyDanger(error);
    }*/
  };

  const capitalize = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <div id="pagecontent-getposts" className="container mt-5">
      <Helmet>
      <link rel="canonical" href={window.location.href} />
        <title>
          Get Auto Insurance Leads in Canada | Beat My Insurance Marketplace
        </title>
        <meta
          name="og:title"
          content="Get Auto Insurance Leads in Canada | Beat My Insurance Marketplace"
        />
        <meta
          name="twitter:title"
          content="Get Auto Insurance Leads in Canada | Beat My Insurance Marketplace"
        />
        <meta
          name="description"
          content="Join Beat My Insurance as a seller, connect with buyers, bid on Auto Insurance listings, and grow your business."
        />
        <meta
          name="og:description"
          content="Join Beat My Insurance as a seller, connect with buyers, bid on Auto Insurance listings, and grow your business."
        />
        <meta
          name="twitter:description"
          content="Join Beat My Insurance as a seller, connect with buyers, bid on Auto Insurance listings, and grow your business."
        />
        <meta
          name="keywords"
          content="Sell Insurance in Canada, Cheap Auto Insurance, Insurance Sellers, Super Visa Insurance, Travel Insurance Canada, Mortgage Rate Quote"
        />
        <meta name="robots" content="index, follow" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Beat My Insurance",
                "item": "https://www.beatmyinsurance.com",
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Sell Insurance",
                "item": "https://www.beatmyinsurance.com/sell-insurance",
              },
            ],
          })}
        </script>
      </Helmet>
      <img
        className="home-icon-si"
        src="/home.png"
        onClick={() => navigate("/")}
        alt="home"
      />
      {sessionExpired && <SessionExpired />}
      <h1 className="get-posts-heading">Search Buyers</h1>
      <div className="get-posts-body">
        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="type" className="form-label">
              Type:
            </label>
            <select
              name="type"
              value={filter.type}
              onChange={handleFilterChange}
              className="form-control"
            >
              <option value="">All</option>
              {types.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faChevronDown} className="low-icon" />
          </div>
          <div className="col-md-2">
            <label htmlFor="state" className="form-label">
              State:
            </label>
            <input
              type="text"
              name="state"
              value={filter.state}
              onChange={handleFilterChange}
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="city" className="form-label">
              City:
            </label>
            <input
              type="text"
              name="city"
              value={filter.city}
              onChange={handleFilterChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="dataholder">
          <div className="tableholder">
            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>City</th>
                  <th>
                    State or
                    <br />
                    Province
                  </th>
                  <th>
                    Current Premium
                    <br />
                    (per month)
                  </th>
                  <th>Posted on</th>
                </tr>
              </thead>
              <tbody>
                {filteredPosts.map((post) => (
                  <tr key={post._id} onClick={() => handleRowClick(post)}>
                    <td>
                      {typeof post.type === "object"
                        ? post.type.name
                        : post.type}
                    </td>
                    <td>{capitalize(post.city)}</td>
                    <td>{capitalize(post.state)}</td>
                    <td>{post.currentPremium || "-"}</td>
                    <td>{post.uploadDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div ref={postDetailsRef} className="otherdetails">
            {selectedPost && (
              <div className="otherdetails-holder-posts">
                <div className="bid-heading-gp">
                  <p>Bid For Post </p>
                </div>
                <div className="bid-btn-holder">
                  <button className="bid-button" onClick={handleBidClick}>
                    <img src="/bid2.png" alt="bid" />
                    Bid
                  </button>
                  <button className="share-button" onClick={handleShareClick}>
                    <img src="/share2.png" alt="share" />
                    Share
                  </button>
                  <button
                    className="message-button-gp"
                    onClick={() => handleBidClick()}
                  >
                    <img src="/message2.png" alt="message" />
                    Message
                  </button>
                </div>
                {showBidPopup && (
                  <div ref={bidPopupRef} className="bid-popup">
                    <form onSubmit={handleBidSubmit}>
                      <span>
                        Note: You have {remainingBids}{" "}
                        {remainingBids === 1 ? "attempt" : "attempts"} left!
                      </span>
                      <input
                        type="text"
                        id="bidAmount"
                        name="bidAmount"
                        value={bidAmount}
                        placeholder="Enter Bid Amount"
                        onChange={(e) => setBidAmount(e.target.value)}
                      />
                      <button className="submit-bid-btn" type="submit">
                        Submit Bid
                      </button>
                    </form>
                  </div>
                )}
                <img
                  src="/cross.png"
                  id="selected-post-cancel"
                  onClick={handleRowClick}
                />
                <div className="details-scroll-getpost">
                  {Object.entries(fields).map(([key, value]) => {
                    if (
                      value.name &&
                      selectedPost[value.name] &&
                      !["_id", "userId", "visibility"].includes(value.name)
                    ) {
                      return (
                        <p key={key} className="other-details-value">
                          {restrictedFields.includes(value.name) &&
                          selectedPost[value.name] === "Verify yourself as seller to view" ? (
                            <>
                              <strong>{formatLabel(value.name)}:</strong>{" "}
                              <span
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() => navigate("/login")}
                              >
                                Verify yourself as Seller to view this detail!
                              </span>
                            </>
                          ) : (
                            <>
                              <strong>{formatLabel(value.name)}:</strong>
                              <span>{selectedPost[value.name]}</span>
                            </>
                          )}
                        </p>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellInsurance;
