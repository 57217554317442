import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import getPostsService from "../services/getPostsService";
import getTypesService from "../services/getTypesService";
import postDetailsProService from "../services/postDetailsProAdmin";
import postDetailsService from "../services/postDetailsAdmin";
import bidService from "../services/bidService";
import restrictedFieldsService from "../services/restrictedFieldsService";
import { sendMessageService } from "../services/messageService";
import "./GetPosts.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import SessionExpired from "./SessionExpired";

const GetPosts = ({ user }) => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [filter, setFilter] = useState({
    type: "",
    city: "",
    state: "",
    currentPremium: "",
    expectedPremium: "",
  });
  const [types, setTypes] = useState([]);
  const [bidAmount, setBidAmount] = useState("");
  const [showBidPopup, setShowBidPopup] = useState(false);
  const [fields, setFields] = useState([]);
  const bidPopupRef = useRef(null);
  const postDetailsRef = useRef(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [remainingBids, setRemainingBids] = useState();
  const [message, setMessage] = useState("");
  const [messagePop, setMessagePop] = useState(false);
  const [restrictedFields, setRestrictedFields] = useState();

  const  navigate = useNavigate();


  const fetchFields = async () => {
    try {
      const fields1 = await postDetailsService.getpostDetails();
      const fields2 = await postDetailsProService.getpostDetailsPro();
      const fields = { ...fields1, ...fields2 };
      setFields(fields);
    } catch (error) {
      console.error("Failed to fetch fields:", error.message);
    }
  };

  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const fetchRestrictedFields = async() =>{
    try{
      const response = await restrictedFieldsService.getFields();
      console.log(response.fields);
      if(!response.error){
        const filteredFields = [];
        response.fields.forEach(field => {
          filteredFields.push(field.name);
        });
        setRestrictedFields(filteredFields || []);
      }
    }catch(error){
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPosts();
    fetchTypes();
    fetchFields();
    fetchRestrictedFields();
    if (localStorage.getItem("BMI-selectedPost")) {
      setSelectedPost(JSON.parse(localStorage.getItem("BMI-selectedPost")));
      localStorage.removeItem("BMI-selectedPost");
    }
  }, []);

  useEffect(() => {
    applyFilter();
  }, [filter, posts]);

  useEffect(() => {
    // Add event listener to detect clicks outside the bid popup
    const handleClickOutside = (event) => {
      if (bidPopupRef.current && !bidPopupRef.current.contains(event.target)) {
        setShowBidPopup(false);
        setBidAmount("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Add event listener to detect clicks outside the bid popup
    const handleClickOutsidePost = (event) => {
      if (
        postDetailsRef.current &&
        !postDetailsRef.current.contains(event.target)
      ) {
        setSelectedPost(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutsidePost);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePost);
    };
  }, []);

  const fetchPosts = async () => {
    try {
      const userData = user;
      const response = await getPostsService.getPosts(userData._id);
      const realposts = response.filter((post) => post.userId !== userData._id);
      setPosts(realposts.reverse());
    } catch (error) {
      console.error("Failed to fetch posts:", error.message);
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await getTypesService.getTypes();
      setTypes(response);
    } catch (error) {
      console.error("Failed to fetch types:", error.message);
    }
  };

  const applyFilter = () => {
    let filtered = [...posts];
    if (filter.type) {
      filtered = filtered.filter((post) =>
        post.type.toLowerCase().includes(filter.type.toLowerCase())
      );
    }
    if (filter.city) {
      filtered = filtered.filter((post) =>
        post.city.toLowerCase().includes(filter.city.toLowerCase())
      );
    }
    if (filter.state) {
      filtered = filtered.filter((post) =>
        post.state.toLowerCase().includes(filter.state.toLowerCase())
      );
    }
    if (filter.currentPremium) {
      filtered = filtered.filter((post) =>
        post.currentPremium
          .toLowerCase()
          .includes(filter.currentPremium.toLowerCase())
      );
    }
    if (filter.expectedPremium) {
      filtered = filtered.filter((post) =>
        post.expectedPremium
          .toLowerCase()
          .includes(filter.expectedPremium.toLowerCase())
      );
    }

    setFilteredPosts(filtered);
  };

  const handleRowClick = (post) => {
    if (selectedPost) {
      setSelectedPost(false);
      setRemainingBids("-");
    } else {
      setSelectedPost(post);
      console.log(post);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleBidClick = async () => {
    setShowBidPopup((prev) => !prev);
    const response = await bidService.remainingBids(
      selectedPost._id,
      user.email
    );

    if (response.bids) {
      setRemainingBids(response.bids);
    } else if (response.error) {
      setRemainingBids("-");
      notifyDanger(response.error);
    } else {
      setRemainingBids(0);
    }
  };
  const handleShareClick = () => {
    const shareData = {
      title: "Check out this post",
      text: "BeatMyInsurance!",
      url: `/sell-insurance/${selectedPost._id}`,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Post shared successfully"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Web Share API not supported in this browser");
    }
  };

  const formatLabel = (str) => {
    return str
      .replace(/([A-Z])/g, " $1") // Insert a space before capital letters
      .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize the first letter
  };

  const capitalize = (text) => {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const handleBidSubmit = async (e) => {
    e.preventDefault();
    try {
      const session = getCookie("BMI-token");
      // Call bid service to submit the bid
      const userData = user;
      // Extract user email from userData

      const userId = userData._id;
      const userEmail = userData.email;
      const userName = userData.name;
      const creatorid = selectedPost.userId;
      const userSubscription = userData.subscription;

      const response = await bidService.submitBid(
        selectedPost._id,
        userId,
        userEmail,
        userName,
        bidAmount,
        creatorid,
        userSubscription,
        session
      );
      setShowBidPopup(false);

      if (response.expired) {
        setSessionExpired(true);
        notifyDanger("Session expired");
        return;
      }
      if (response.error) {
        notifyDanger(response.error);
      } else if (response.payment) {
        notifyDanger(response.payment);
        window.location.href = "https://www.beatmyinsurance.com/main/payments";
      } else {
        notifySuccess(response.message);
        handleBidClick();
      }
      setBidAmount("");
    } catch (error) {
      notifyDanger(error);
    }
  };

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    const formData = {};
    const userData = user;
    formData.message = message;
    formData.from = userData._id;
    formData.to = selectedPost.userId;
    try {
      const response = await sendMessageService(formData);
      if (response.payment) {
        notifyDanger(response.payment);
      } else if (response.message) {
        notifySuccess(response.message);
        setMessagePop(false);
        setMessage("");
      } else {
        notifyDanger(response.error || "Something went wrong");
      }
    } catch (error) {
      notifyDanger("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div id="pagecontent-getposts" className="container mt-5">
      {sessionExpired && <SessionExpired />}
      <h1 className="get-posts-heading">Search Buyers</h1>
      <div className="get-posts-body">
        <div className="row mb-3">
          <div className="col-md-2">
            <label htmlFor="type" className="form-label">
              Type:
            </label>
            <select
              name="type"
              value={filter.type}
              onChange={handleFilterChange}
              className="form-control"
            >
              <option value="">All</option>
              {types.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <FontAwesomeIcon icon={faChevronDown} className="low-icon" />
          </div>
          <div className="col-md-2">
            <label htmlFor="state" className="form-label">
              State:
            </label>
            <input
              type="text"
              name="state"
              value={filter.state}
              onChange={handleFilterChange}
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="city" className="form-label">
              City:
            </label>
            <input
              type="text"
              name="city"
              value={filter.city}
              onChange={handleFilterChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="dataholder">
          <div className="tableholder">
            <table className="table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>City</th>
                  <th>
                    State or
                    <br />
                    Province
                  </th>
                  <th>
                    Current Premium
                    <br />
                    (per month)
                  </th>
                  <th>Posted on</th>
                </tr>
              </thead>
              <tbody>
                {filteredPosts.map((post) => (
                  <tr key={post._id} onClick={() => handleRowClick(post)}>
                    <td>
                      {typeof post.type === "object"
                        ? post.type.name
                        : post.type}
                    </td>
                    <td>{capitalize(post.city)}</td>
                    <td>{capitalize(post.state)}</td>
                    <td>{post.currentPremium || "-"}</td>
                    <td>{post.uploadDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div ref={postDetailsRef} className="otherdetails">
            {selectedPost && (
              <div className="otherdetails-holder-posts">
                <div className="bid-heading-gp">
                  <p>Bid For Post </p>
                </div>
                <div className="bid-btn-holder">
                  <button className="bid-button" onClick={handleBidClick}>
                    <img src="/bid2.png" alt="bid" />
                    Bid
                  </button>
                  <button className="share-button" onClick={handleShareClick}>
                    <img src="/share2.png" alt="share" />
                    Share
                  </button>
                  <button
                    className="message-button-gp"
                    onClick={() => setMessagePop(!messagePop)}
                  >
                    <img src="/message2.png" alt="message" />
                    Message
                  </button>
                </div>
                {messagePop && (
                  <form
                    className="message-input-holder"
                    onSubmit={handleMessageSubmit}
                  >
                    <input
                      type="text"
                      placeholder="Enter your message here(max 160 characters)"
                      autoFocus
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button onClick={(e) => handleMessageSubmit(e)}>
                      <img src="/send.png" alt="send" />
                    </button>
                  </form>
                )}
                {showBidPopup && (
                  <div ref={bidPopupRef} className="bid-popup">
                    <form onSubmit={handleBidSubmit}>
                      <span>
                        Note: You have {remainingBids}{" "}
                        {remainingBids === 1 ? "attempt" : "attempts"} left!
                      </span>
                      <input
                        type="text"
                        id="bidAmount"
                        name="bidAmount"
                        value={bidAmount}
                        placeholder="Enter Bid Amount"
                        onChange={(e) => setBidAmount(e.target.value)}
                      />
                      <button className="submit-bid-btn" type="submit">
                        Submit Bid
                      </button>
                    </form>
                  </div>
                )}
                <img
                  src="/cross.png"
                  id="selected-post-cancel"
                  onClick={handleRowClick}
                />
                <div className="details-scroll-getpost">
                  {Object.entries(fields).map(([key, value]) => {
                    if (
                      value.name &&
                      selectedPost[value.name] &&
                      !["_id", "userId", "visibility"].includes(value.name)
                    ) {
                      return (
                        <p key={key} className="other-details-value">
                          {restrictedFields.includes(value.name) &&
                          selectedPost[value.name] === "Verify yourself as seller to view" ? (
                            <>
                              <strong>{formatLabel(value.name)}:</strong>{" "}
                              <span style={{cursor:"pointer", textDecoration: "underline"}} onClick={()=> navigate("/main/profile")}>Verify yourself as Seller to view this detail!</span>
                            </>
                            
                          ) : (
                            <>
                              <strong>{formatLabel(value.name)}:</strong>
                              <span>{selectedPost[value.name]}</span>
                            </>
                          )}
                        </p>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetPosts;
