import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const postDetailsService = {
  getpostDetails: async () => {
    try {
      const response = await fetch(`${API_URL}/postDetails`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'bmi_api_key': bmi_api_key, 
          }
        });
      const data = await response.json();
      
      return data.types;
    } catch (error) {
      console.error('Failed to fetch fields:', error.message);
      throw new Error('Failed to fetch fields');
    }
  },
};

export default postDetailsService;
