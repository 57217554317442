import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './AutoInsurance.css';
import DynamicMeta from "../components/DynamicMeta";
import { fetchCities } from "../services/citiesService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const AutoInsurance = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [device, setDevice] = useState();
  const [tutorialType, setTutorialType] = useState();
  const [city, setCity] = useState("-");
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState();
  const queryCity = useParams();

  const navigate = useNavigate();

  const getCities = async()=>{
    setLoading(true);
    const response = await fetchCities();
    if(response.cities){
        setCities(response.cities);
        if(response.cities.includes(queryCity.city)) setCity(queryCity.city);
        setLoading(false);
    }
    else{
        notifyDanger("Something went wrong!");
    }
  };

  useEffect(()=>{
    getCities();
  },[]);
  const notifySuccess = (alertMessage) => {
    toast.success(alertMessage);
  };

  const notifyDanger = (alertMessage) => {
    toast.error(alertMessage);
  };

  const detectDevice = () => {
    const width = window.innerWidth;
    if (width >= 1000) {
      setDevice("desktop");
    } else if (width >= 550) {
      setDevice("tab");
    } else {
      setDevice("mobile");
    }
  };

  // Detect screen size on mount and on window resize
  useEffect(() => {
    detectDevice(); // Initial detection
    window.addEventListener("resize", detectDevice); // Re-detect on resize

    return () => {
      window.removeEventListener("resize", detectDevice); // Cleanup on unmount
    };
  }, []);
  return (
    <div className="auto-insurance-page">
      <Helmet>
      <title>
          Best Auto Insurance Deals Near You in {city}
        </title>
        <meta
          name="og:title"
          content={`Best Auto Insurance Deals Near You in ${city} `}
        />
        <meta
          name="twitter:title"
          content={`Best Auto Insurance Deals Near You in ${city} `}
        />
        <meta
          name="description"
          content={`Find the best auto insurance rates in ${city} - fast, anonymous, and secure. Stay updated on the latest insurance and mortgage trends!`}
        />
        <meta
          name="og:description"
          content={`Find the best auto insurance rates in ${city} - fast, anonymous, and secure. Stay updated on the latest insurance and mortgage trends!`}
        />
        <meta
          name="twitter:description"
          content={`Find the best auto insurance rates in ${city} - fast, anonymous, and secure. Stay updated on the latest insurance and mortgage trends!`}
        />
        
        <meta name="robots" content="index, follow" />
      <link rel="canonical" href={window.location.href} />
      <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "@id": "#Organization",
            url: "https://www.beatmyinsurance.com",
            legalName: "Beat My Insurance",
            name: "Beat My Insurance",
            description:
              "Get the best auto, home, life, and super visa insurance with Beat My Insurance! Easily compare options to find cheap car insurance, auto insurance, home insurance in Canada, and bundled home and auto insurance deals.",
            image: "https://www.beatmyinsurance.com/logo.png",
            logo: "https://www.beatmyinsurance.com/logo.png",
            
            email: "info@beatmyinsurance.com",
            address: {
              "@type": "PostalAddress",
              addressRegion: "Ontario",
              addressCountry: "Canada",
              
            },
            sameAs: [
              "https://www.facebook.com/beatmyinsurance/",
              "https://www.youtube.com/@BeatMyInsurance",
              "https://www.instagram.com/beatmyinsurance/",
              "https://www.linkedin.com/company/beat-my-insurance/",
              "https://x.com/Beatmyinsurance",
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Beat My Insurance",
                item: "https://www.beatmyinsurance.com",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Buy Insurance",
                item: "https://www.beatmyinsurance.com/buy-insurance",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Sell Insurance",
                item: "https://www.beatmyinsurance.com/sell-insurance",
              },
              {
                "@type": "ListItem",
                position: 4,
                name: "Cheap Insurance Car Finder",
                item: "https://www.beatmyinsurance.com/cheap-insurance-car-finder",
              },
              {
                "@type": "ListItem",
                position: 5,
                name: "Mortgage Penalty Calculator",
                item: "https://www.beatmyinsurance.com/mortgage-penalty-calculator",
              },
              {
                "@type": "ListItem",
                position: 6,
                name: "About Us",
                item: "https://www.beatmyinsurance.com/about",
              },
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "@id": "#WebPage",
            url: "https://www.beatmyinsurance.com/cheap-insurance-car-finder",
            name: "Cheap Insurance Car Finder",
            areaServed: [
              "https://en.wikipedia.org/wiki/Canada",
              "https://en.wikipedia.org/wiki/Ottawa",
              "https://en.wikipedia.org/wiki/London,_Ontario",
              "https://en.wikipedia.org/wiki/Hamilton,_Ontario",
              "https://en.wikipedia.org/wiki/Vaughan",
              "https://en.wikipedia.org/wiki/Toronto",
              "https://en.wikipedia.org/wiki/Mississauga",
              "https://en.wikipedia.org/wiki/Brampton",
              "https://en.wikipedia.org/wiki/Windsor,_Ontario",
              "https://en.wikipedia.org/wiki/Kitchener,_Ontario",
              "https://en.wikipedia.org/wiki/Markham,_Ontario",
            ],
          })}
        </script>
      </Helmet>
      {city? <DynamicMeta title={`Best Auto Insurance Deals Near You in ${city}`} description={`Find the best auto insurance rates in ${city} - fast, anonymous, and secure. Stay updated on the latest insurance and mortgage trends!`} keywords= {`Best Auto Insurance ${city}, Auto Insurance Rates ${city}, Insurance Trends ${city}, Mortgage Trends ${city}, ${city} Insurance `}/> : ""}
        <ToastContainer/>
        {loading && (
            <div className="loading-auto-insurance">
                <img src="/loading.gif" alt="loading"/>
            </div>
        )}
        {!loading && ( <>
      {showTutorial && (
        <div className="home-tutorial-holder">
          <div className="home-tutorial-btnholder">
            <button
              className={
                tutorialType === "buy"
                  ? "tutorial-home-btn-active"
                  : "tutorial-home-btn"
              }
              onClick={() => setTutorialType("buy")}
            >
              Buyer
            </button>
            <button
              className={
                tutorialType === "sell"
                  ? "tutorial-home-btn-active"
                  : "tutorial-home-btn"
              }
              onClick={() => setTutorialType("sell")}
            >
              Seller
            </button>
            <img
              src="/cross.png"
              alt="close"
              onClick={() => setShowTutorial(false)}
            />
          </div>
          <div className="home-video-holder">
            <video
              src={`https://profiles-bmi.s3.amazonaws.com/${tutorialType}-${device}.mp4`}
              controls
            />
          </div>
        </div>
      )}
      <div className="navbar">
        <img className="logo" src="/logo.png" alt="logo" />
        {device !== "desktop" && (
          <>
            <img
              className="menu-icon-home"
              src="/menu.png"
              alt="menu"
              onClick={() => setShowMenu(!showMenu)}
            />

            <div className={`menu-options-home ${showMenu ? "open" : ""}`}>
              <button
                className="calculator-tool-btn-m"
                onClick={() => {
                  navigate("/cheap-insurance-car-finder");
                }}
              >
                <p>Cheap Car Insurance Finder</p>
                <img src="/car.png" alt="how it works" />
              </button>
              <button
                className="tutorial-btn-home-m"
                onClick={() => {
                  setShowTutorial(true);
                }}
              >
                <p>How it works</p>
                <img src="/help-circle.png" alt="how it works" />
              </button>
              <button
                className="aboutus-btn-home-m"
                onClick={() => {
                  navigate("/about");
                }}
              >
                <p>About Us</p>
                <img src="/alert-circle.png" alt="how it works" />
              </button>
              <button
                className="login-m"
                onClick={() => {
                  navigate("/login", { state: { newUser: false } });
                }}
              >
                Login / Signup
              </button>
            </div>
          </>
        )}
        {device === "desktop" && (
          <>
            <button
              className="calculator-tool-btn"
              onClick={() => {
                navigate("/cheap-insurance-car-finder");
              }}
            >
              <p>Cheap Car Insurance Finder</p>
              <img src="/car.png" alt="how it works" />
            </button>
            <button
              className="tutorial-btn-home"
              onClick={() => {
                setShowTutorial(true);
              }}
            >
              <p>How it works</p>
              <img src="/help-circle.png" alt="how it works" />
            </button>
            
            <button
              className="login"
              onClick={() => {
                navigate("/login", { state: { newUser: false } });
              }}
            >
              Sign In
            </button>
          </>
        )}
      </div>
      <h1 className="best-auto-insurance">
        Best Auto Insurance Deals Near You in {city}
      </h1>
      <p className="best-auto-insurance-rates">
      Best Auto Insurance Rates in {city} – 
      Quickly, Anonymously, and Confidently.
      </p>

      <p className="step1-auto-insurance">
      Step 1: Know about your car with this tool
      </p>
      <button
        className="cheap-insurance-btn-auto-insurance"
        onClick={() => navigate("/cheap-insurance-car-finder")}
      >
        Cheap Insurance Car Finder
      </button>
      <p className="step2-auto-insurance">
      Step 2: List your car details
      </p>
      {/*<div className="add-listing-auto-insurance">
        <img src="/home-p1-background.png" alt="background image"/>
        <div className="need-insurance-box-auto-insurance">
            <div className="btn-holder-auto-insurance">
              <div className="select-wrapper-auto-insurance">
              <select
                defaultValue="none"
                onChange={(e) => {
                  if (e.target.value === "Add New Listing") {
                    navigate("/buy-insurance");
                  } else if (e.target.value === "Check My Listings") {
                    navigate("/login", { state: { newUser: false } });
                  }
                }}
              >
                <option value="none" disabled hidden>
                  Add New Listing
                </option>
                <option value="Add New Listing">Add New Listing</option>
                <option value="Check My Listings">Check My Listings</option>
              </select>
              </div>
            </div>
            <div className="need-insurance-box-content-auto-insurance">
              <p className="need-insurance-heading-auto-insurance">
                Let’s beat your current premium!
              </p>
              <p className="need-insurance-sidehead-auto-insurance">
                Hola! No more chasing brokers for quotes.
              </p>
              <div className="need-insurance-info-auto-insurance">
                <div>Stay anonymous</div>
                <div>Post your needs</div>
                <div>Choose the best premium</div>
              </div>
              <br />
            </div>
        </div>
      </div> */}
      <div className="new-listing-btn-auto-insurance">
      <button  onClick={()=>navigate('/buy-insurance')}>Add new listing</button>
      </div>
      <p className="step3-auto-insurance">
        Step 3: Receive competitive bids{" "}
      </p>


      <p className="step4-auto-insurance">
        Step 4: Pick the bid that fits your pocket!
      </p>
      <p className="step5-auto-insurance">
        Step 5: Chat with sellers and close the deal
      </p>
      <p className="stay-updated-auto-insurance">
      Stay updated on the latest insurance and mortgage trends in {city} </p>

      <div className="join-us-auto-insurance">
        <p>Join us now for a prosperous and secure future</p>
        <button onClick={() => {
                navigate("/login", { state: { newUser: true } });
              }}>Get Started Today</button>
      </div>
      <div className="auto-insurance-cities">
              {cities && cities.map((cityName)=>(
                <a key={cityName} href={`/best-auto-insurance/${cityName}`}>{cityName}</a>
              ))}
      </div>
      <div className="footer-auto-insurance">
        <span className="copyright">
          Copyright 2024 BMI. All Rights Reserved
        </span>
        <span className="liner-footer">|</span>
        <div className="footer-icons-holder">
          <img
            onClick={() => {
              window.open(
                "https://www.facebook.com/beatmyinsurance/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="/facebook.svg"
            alt="facebook"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.instagram.com/beatmyinsurance/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="/instagram.svg"
            alt="instagram"
          />
          <img
            onClick={() => {
              window.open(
                "http://www.youtube.com/@BeatMyInsurance",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="/youtube.svg"
            alt="youtube"
          />
          <img
            onClick={() => {
              window.open(
                "https://x.com/Beatmyinsurance",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="/twitter.svg"
            alt="twitter"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/103720775/admin/feed/posts/",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            src="/linkedin.svg"
            alt="linkedin"
          />
        </div>
        <span
          onClick={() => {
            window.open(
              "https://www.beatmyinsurance.com/blogs/",
              "_blank",
              "noopener,noreferrer"
            );
          }}
          className="blogs"
        >
          blogs
        </span>
        <span
          onClick={() => {
            window.open(
              "https://www.beatmyinsurance.com/terms/",
              "_blank",
              "noopener,noreferrer"
            );
          }}
          className="terms"
        >
          Terms of Use | Privacy Policy
        </span>
      </div>
      </>)}
    </div>
  );
};
export default AutoInsurance;
