import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const uploadImage = async (userId, imageUrl, image) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("userId", userId);
  formData.append("OldImageUrl", imageUrl);

  const response = await fetch(`${API_URL}/upload`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Image upload failed");
  }

  return response.json();
};

export default uploadImage;
