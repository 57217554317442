import config from "../utils/ApiUrl";
const API_URL = config.API_URL;

const sendMessageService = async (formData) => {
  const response = await fetch(`${API_URL}/send-message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  });

  return response.json();
};

const getChatsService = async (userId) => {
  try {
    const response = await fetch(`${API_URL}/get-chats/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json();
  } catch (error) {
    return false;
  }
};

const sendReplyService = async (formData) => {
    const response = await fetch(`${API_URL}/send-reply`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
  
    return response.json();
  };

const fetchChatsCount = async (userId) => {
  const response = await fetch(`${API_URL}/get-chats-count/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
};

const markChatAsRead = async(chatKey, userId) =>{
  const response = await fetch(`${API_URL}/mark-chat-read/${chatKey}/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
}


export { sendMessageService, getChatsService, sendReplyService, fetchChatsCount , markChatAsRead};
