import React from "react";
import { useNavigate } from "react-router-dom";
import "./CalculationResult.css"; // Import the CSS file

// Helper function to determine the color class based on the value
const getBoxColor = (value) => {
  if (typeof value !== "number") return "white";
  if (value < 81) return "green";
  if (value >= 81 && value <= 120) return "yellow";
  return "red";
};

// Helper function to determine the overall result message and style
const getOverallConclusion = (data) => {
  const values = Object.values(data).filter((val) => typeof val === "number");

  const greenCount = values.filter((val) => val < 81).length;
  const yellowCount = values.filter((val) => val >= 81 && val <= 120).length;
  const redCount = values.filter((val) => val > 120).length;

  if (greenCount === values.length) {
    return { message: "Perfecto! It’s a great search.", color: "green" };
  } else if (yellowCount >= redCount) {
    return { message: "Nice! But see if there is another option.", color: "yellow" };
  } else {
    return { message: "Hmm, there’s something to look into.", color: "red" };
  }
};

const colorsRatio =  (data) => {
    const values = Object.values(data).filter((val) => typeof val === "number");
    const greenCount = values.filter((val) => val < 81).length;
    const yellowCount = values.filter((val) => val >= 81 && val <= 120).length;
    const redCount = values.filter((val) => val > 120).length;
    const whiteCount = 4-greenCount-yellowCount-redCount;
    return [greenCount,yellowCount,redCount,whiteCount];

};

const CalculationResult = (props) => {
  const {
    Collision,
    CompensationIncludingTheft,
    CompensationPropertyDamage,
    AccidentalBenefits,
  } = props.data;

  const navigate = useNavigate();

  const colors =  ["green","yellow","red","white"];
  const ratios = colorsRatio({Collision, CompensationIncludingTheft,CompensationPropertyDamage, AccidentalBenefits});

  const overallConclusion = getOverallConclusion({Collision, CompensationIncludingTheft,CompensationPropertyDamage, AccidentalBenefits});

  const customColor = (actualColor) =>{
    if (actualColor === "red") return "rgb(230, 103, 112)";
    if (actualColor === "yellow") return "rgb(235, 229, 73)";
    if  (actualColor === "green") return "rgb(53, 176, 63)";
    if (actualColor === "white") return "rgb(173, 177, 179)";
  }

  return (
    <>
      <div className="popup-backdrop" onClick={props.handleResultClose}></div>
      <div className="calculation-result">
        <div className="logo-calculation">
            <img src="/logo.png" alt="beatmyinsurance"/>
        </div>
        <div className="result-boxes">
          {/* Collision Box */}
          <div className={`result-box ${getBoxColor(Collision)}`}>
            <p className="result-box-h"><strong>Collision </strong>: {Collision || "No Data"}</p>
            {getBoxColor(Collision) === "green" && (
              <p>
                Low insurance risk (less than 81 on the claims cost index).
              </p>
            )}
            {getBoxColor(Collision) === "yellow" && (
              <p>
                Moderate insurance risk (between 81 and 120 on the index),
                representing average claim costs and premiums.
              </p>
            )}
            {getBoxColor(Collision) === "red" && (
              <p>
                High insurance risk (above 120 on the index), indicating more
                expensive claims and higher insurance premiums.
              </p>
            )}
            {getBoxColor(Collision) === "white" && <p>No data found!</p>}
          </div>

          {/* Compensation Including Theft Box */}
          <div className={`result-box ${getBoxColor(CompensationIncludingTheft)}`}>
            <p className="result-box-h"><strong>Compensation Including Theft: </strong>{CompensationIncludingTheft || "No Data"}</p>
            {getBoxColor(CompensationIncludingTheft) === "green" && (
              <p>Low insurance risk (less than 81 on the claims cost index).</p>
            )}
            {getBoxColor(CompensationIncludingTheft) === "yellow" && (
              <p>Moderate insurance risk (between 81 and 120 on the index).</p>
            )}
            {getBoxColor(CompensationIncludingTheft) === "red" && (
              <p>High insurance risk (above 120 on the index).</p>
            )}
            {getBoxColor(CompensationIncludingTheft) === "white" && <p>No data found!</p>}
          </div>

          
          <div className={`result-box ${getBoxColor(CompensationPropertyDamage)}`}>
            <p className="result-box-h"><strong>Compensation Property Damage: </strong> {CompensationPropertyDamage || "No Data"}</p>
            {getBoxColor(CompensationPropertyDamage) === "green" && (
              <p>Low insurance risk (less than 81 on the claims cost index).</p>
            )}
            {getBoxColor(CompensationPropertyDamage) === "yellow" && (
              <p>Moderate insurance risk (between 81 and 120 on the index).</p>
            )}
            {getBoxColor(CompensationPropertyDamage) === "red" && (
              <p>High insurance risk (above 120 on the index).</p>
            )}
            {getBoxColor(CompensationPropertyDamage) === "white" && <p>No data found!</p>}
          </div>

          {/* Accidental Benefits Box */}
          <div className={`result-box ${getBoxColor(AccidentalBenefits)}`}>
            <p className="result-box-h"><strong>Accidental Benefits:</strong> {AccidentalBenefits || "No Data"}</p>
            {getBoxColor(AccidentalBenefits) === "green" && (
              <p>Low insurance risk (less than 81 on the claims cost index).</p>
            )}
            {getBoxColor(AccidentalBenefits) === "yellow" && (
              <p>Moderate insurance risk (between 81 and 120 on the index).</p>
            )}
            {getBoxColor(AccidentalBenefits) === "red" && (
              <p>High insurance risk (above 120 on the index).</p>
            )}
            {getBoxColor(AccidentalBenefits) === "white" && <p>No data found!</p>}
          </div>
        </div>

        {/* Overall Result Conclusion */}
        <div className={`overall-result-conclusion`}>
            {colors.map((color, index)=>(
                <div  key={index} className={`color-box`} style={{backgroundColor: `${customColor(color)}`,width:`${(ratios[index]/4)* 100}%`}}></div>
            ))}
          <p>{overallConclusion.message} </p>
        </div>

        <div className="btns-calculation-result">
            <button onClick={props.handleResultClose}>Search Another</button>
            <button onClick={()=>navigate('/buy-insurance/auto-insurance')}>Add New Listing</button>
        </div>
      </div>
    </>
  );
};

export default CalculationResult;
