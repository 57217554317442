import config from "../utils/ApiUrl";
const API_URL = config.API_URL;
const bmi_api_key = process.env.REACT_APP_BMI_API_KEY;

const userService = () => ({
  register: async (userData) => {
    try {
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
       
      if (!response.ok) {
        throw new Error('Registration failed1');
      }
      return response.json();
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  },

  login: async (userData) => {
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
      /*
      if (!response.ok) {
        throw new Error('Login failed');
      }*/
      return response.json();
    } catch (error) {
      throw new Error('Login failed');
    }
  },
  
  getUser: async (token) => {
    try {
      const response = await fetch(`${API_URL}/user/${token}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        return(false);
      }
      return response.json();
    } catch (error) {
      return(false);
    }
  },

  deleteAccount: async(userId,password) =>{
    try {
      const response = await fetch(`${API_URL}/delete-account`, {
        method: 'POST',
        headers: {
          'bmi_api_key': bmi_api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userId:userId, password:password}),
      });
      
      return response.json();
    } catch (error) {
      return(false);
    }
  },

});

export default userService();
