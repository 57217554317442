import React from 'react';
import './Terms.css';
import {Helmet} from 'react-helmet-async';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <Helmet>
      <link rel="canonical" href={window.location.href} />
        <title>Terms and Conditions | Beat My Insurance</title>
        <meta
          name="description"
          content="Review Terms and Conditions on Beat My Insurance. Know your rights and responsibilities as a buyer or seller."
        />
        <meta
          property="og:title"
          content="Terms and Conditions | Beat My Insurance"
        />
        <meta
          property="og:description"
          content="Review Terms and Conditions on Beat My Insurance. Know your rights and responsibilities as a buyer or seller."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Beat My Insurance" />
        <meta name="twitter:card" content="summary_large_image" />{" "}
        <meta
          name="twitter:title"
          content="Terms and Conditions | Beat My Insurance"
        />{" "}
        <meta
          name="twitter:description"
          content="Review Terms and Conditions on Beat My Insurance. Know your rights and responsibilities as a buyer or seller."
        />{" "}
        <meta
          name="twitter:image"
          content="https://www.beatmyinsurance.com/logo512.png"
        />{" "}
        <meta name="twitter:site" content="@Beatmyinsurance" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "@id": "#Organization",
            url: "https://www.beatmyinsurance.com",
            legalName: "Beat My Insurance",
            name: "Beat My Insurance",
            description:
              "Get the best auto, home, life, and super visa insurance with Beat My Insurance! Easily compare options to find cheap car insurance, auto insurance, home insurance in Canada, and bundled home and auto insurance deals.",
            image: "https://www.beatmyinsurance.com/logo.png",
            logo: "https://www.beatmyinsurance.com/logo.png",
            
            email: "info@beatmyinsurance.com",
            address: {
              "@type": "PostalAddress",
              addressRegion: "Ontario",
              addressCountry: "Canada",
              
            },
            sameAs: [
              "https://www.facebook.com/beatmyinsurance/",
              "https://www.youtube.com/@BeatMyInsurance",
              "https://www.instagram.com/beatmyinsurance/",
              "https://www.linkedin.com/company/beat-my-insurance/",
              "https://x.com/Beatmyinsurance",
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Beat My Insurance",
                item: "https://www.beatmyinsurance.com",
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Buy Insurance",
                item: "https://www.beatmyinsurance.com/buy-insurance",
              },
              {
                "@type": "ListItem",
                position: 3,
                name: "Sell Insurance",
                item: "https://www.beatmyinsurance.com/sell-insurance",
              },
              {
                "@type": "ListItem",
                position: 4,
                name: "Cheap Insurance Car Finder",
                item: "https://www.beatmyinsurance.com/cheap-insurance-car-finder",
              },
              {
                "@type": "ListItem",
                position: 5,
                name: "Mortgage Penalty Calculator",
                item: "https://www.beatmyinsurance.com/mortgage-penalty-calculator",
              },
              {
                "@type": "ListItem",
                position: 6,
                name: "About Us",
                item: "https://www.beatmyinsurance.com/about",
              },
            ],
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "@id": "#WebPage",
            url: "https://www.beatmyinsurance.com/cheap-insurance-car-finder",
            name: "Cheap Insurance Car Finder",
            areaServed: [
              "https://en.wikipedia.org/wiki/Canada",
              "https://en.wikipedia.org/wiki/Ottawa",
              "https://en.wikipedia.org/wiki/London,_Ontario",
              "https://en.wikipedia.org/wiki/Hamilton,_Ontario",
              "https://en.wikipedia.org/wiki/Vaughan",
              "https://en.wikipedia.org/wiki/Toronto",
              "https://en.wikipedia.org/wiki/Mississauga",
              "https://en.wikipedia.org/wiki/Brampton",
              "https://en.wikipedia.org/wiki/Windsor,_Ontario",
              "https://en.wikipedia.org/wiki/Kitchener,_Ontario",
              "https://en.wikipedia.org/wiki/Markham,_Ontario",
            ],
          })}
        </script>
      </Helmet>
      <h1>Terms and Conditions</h1>
      <ol>
        <li>
          <h2>Introduction</h2>
          <p>
            Welcome to BeatMyInsurance.com. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.
          </p>
        </li>
        <li>
          <h2>Services</h2>
          <p>
            BeatMyInsurance.com provides a platform for comparing insurance policies from various providers. We do not sell insurance products directly but facilitate the process of finding and purchasing insurance from third-party providers.
          </p>
        </li>
        <li>
          <h2>No Guarantees</h2>
          <p>
            While we strive to provide accurate and up-to-date information, BeatMyInsurance.com makes no warranties or guarantees regarding the accuracy, completeness, or reliability of any information on the site. The information provided is for general informational purposes only and should not be considered professional advice.
          </p>
        </li>
        <li>
          <h2>All Sales Are Final</h2>
          <p>
            All transactions made through BeatMyInsurance.com are final. No refunds will be issued for any reason. By using our services, you agree to this no-refund policy.
          </p>
        </li>
        <li>
          <h2>Limitation of Liability</h2>
          <p>
            BeatMyInsurance.com shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of, or inability to use, this website or the services provided. This includes, but is not limited to, loss of data, loss of profits, or interruption of business.
          </p>
        </li>
        <li>
          <h2>User Responsibilities</h2>
          <p>
            You are responsible for ensuring that any information you provide is accurate, complete, and up-to-date. You agree not to use the website for any unlawful purpose or in any way that could damage, disable, overburden, or impair the website.
          </p>
        </li>
        <li>
          <h2>Changes to Terms</h2>
          <p>
            BeatMyInsurance.com reserves the right to revise these terms and conditions at any time. By using this website, you agree to be bound by the current version of these terms and conditions.
          </p>
        </li>
        <li>
          <h2>Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of Canada - Ontario, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
