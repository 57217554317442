"use client";

import React, { useState, ChangeEvent } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { DollarSign, ArrowDownCircle, ArrowUpCircle, Info, ChevronDown, ChevronUp, AlertCircle } from "lucide-react";

const Card = ({ children, className }) => (
  <div className={`border rounded-lg shadow-sm p-4 ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="border-b pb-2 mb-4">{children}</div>;

const CardTitle = ({ children }) => <h2 className="text-lg font-bold">{children}</h2>;

const CardContent = ({ children }) => <div>{children}</div>;

const Input = ({ type, name, value, onChange, step, className }) => (
  <input
    type={type}
    name={name}
    value={value}
    onChange={onChange}
    step={step}
    className={`border rounded-lg p-2 w-full ${className}`}
  />
);

const Button = ({ children, onClick, className }) => (
  <button onClick={onClick} className={`px-4 py-2 rounded-lg font-bold ${className}`}>
    {children}
  </button>
);

const Select = ({ children, onValueChange, defaultValue }) => {
  const handleChange = (event) => onValueChange(event.target.value);
  return (
    <select onChange={handleChange} defaultValue={defaultValue} className="border rounded-lg p-2 w-full">
      {children}
    </select>
  );
};

const SelectItem = ({ value, children }) => <option value={value}>{children}</option>;

const Alert = ({ children, className }) => (
  <div className={`p-4 rounded-lg border ${className}`}>{children}</div>
);

const AlertDescription = ({ children, className }) => (
  <p className={`text-sm ${className}`}>{children}</p>
);

const Collapsible = ({ children, open, onOpenChange }) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    onOpenChange(!isOpen);
  };
  return <div>{children(handleToggle, isOpen)}</div>;
};

const tooltips = {
  mortgageType: "Choose between Fixed Rate (locked-in rate) or Variable Rate (fluctuates with market)",
  currentBalance: "The remaining principal amount on your current mortgage",
  currentRate: "Your current mortgage interest rate",
  reinvestmentRate: "The rate at which the bank can reinvest prepaid funds (typically current posted rate for your remaining term)",
  newRate: "The interest rate being offered by the new lender",
  pendingTerm: "Number of months remaining in your current mortgage term",
  dischargeFee: "Fee charged by current lender to discharge the mortgage",
  lawyerFee: "Legal fees for processing the new mortgage",
  otherFee: "Any additional administrative fees",
  appraisalFee: "Fee for property appraisal if required",
  cashbackAmount: "Any cashback offered by the new lender",
};

const NewMortgagePenaltyCalculator = () => {
  const [formData, setFormData] = useState({
    mortgageType: "fixed",
    currentBalance: "",
    currentRate: "",
    reinvestmentRate: "",
    pendingTerm: "",
    dischargeFee: "",
    lawyerFee: "",
    otherFee: "",
    appraisalFee: "",
    cashbackAmount: "",
    newRate: "",
  });

  const [results, setResults] = useState(null);
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const calculateMonthlyPayment = (principal, rate, term) => {
    const monthlyRate = rate / 100 / 12;
    return (principal * (monthlyRate * Math.pow(1 + monthlyRate, term))) / (Math.pow(1 + monthlyRate, term) - 1);
  };

  const calculatePenalty = () => {
    const balance = parseFloat(formData.currentBalance);
    const rate = parseFloat(formData.currentRate) / 100;
    const reinvestRate = parseFloat(formData.reinvestmentRate) / 100;
    const term = parseFloat(formData.pendingTerm);

    const threeMonthsPenalty = balance * (rate / 12) * 3;
    let irdPenalty = null;

    if (formData.mortgageType === "fixed") {
      irdPenalty = balance * (rate - reinvestRate) * (term / 12);
    }

    const finalPenalty = formData.mortgageType === "fixed" ? Math.max(threeMonthsPenalty, irdPenalty || 0) : threeMonthsPenalty;

    return { threeMonthsPenalty, irdPenalty, finalPenalty };
  };

  const calculate = () => {
    const penaltyResults = calculatePenalty();
    const balance = parseFloat(formData.currentBalance);
    const currentRate = parseFloat(formData.currentRate);
    const newRate = parseFloat(formData.newRate);
    const term = parseFloat(formData.pendingTerm);

    const currentMonthlyPayment = calculateMonthlyPayment(balance, currentRate, term);
    const newMonthlyPayment = calculateMonthlyPayment(balance, newRate, term);
    const monthlySavings = currentMonthlyPayment - newMonthlyPayment;

    const adminFeesTotal = ["dischargeFee", "lawyerFee", "otherFee", "appraisalFee"]
      .map((fee) => parseFloat(formData[fee] || "0"))
      .reduce((sum, fee) => sum + fee, 0);
    const cashbackAmount = parseFloat(formData.cashbackAmount || "0");
    const totalCost = penaltyResults.finalPenalty + adminFeesTotal - cashbackAmount;
    const monthsToBreakEven = Math.ceil(totalCost / monthlySavings);

    const trajectoryData = Array.from({ length: term + 1 }, (_, month) => ({
      month,
      savings: monthlySavings * month - totalCost,
    }));

    setResults({
      monthlySavings,
      totalCost,
      rateDifference: currentRate - newRate,
      ...penaltyResults,
      adminFeesTotal,
      cashbackAmount,
      monthsToBreakEven,
      yearsToBreakEven: monthsToBreakEven / 12,
      trajectoryData,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (value) => setFormData((prev) => ({ ...prev, mortgageType: value }));

  return (
    <Card className="w-full max-w-4xl mx-auto">
      {/* Header */}
      <CardHeader>
        <CardTitle>Mortgage Penalty Calculator</CardTitle>
      </CardHeader>

      {/* Form */}
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3>Basic Information</h3>
            {Object.keys(tooltips).map((key) => (
              <div key={key}>
                <label>{tooltips[key]}</label>
                <Input
                  type={key.includes("Rate") ? "number" : "text"}
                  name={key}
                  value={formData[key]}
                  onChange={handleInputChange}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Results */}
        <Button className="bg-blue-500 text-white" onClick={calculate}>
          Calculate
        </Button>
        {results && <div>Results: {JSON.stringify(results)}</div>}
      </CardContent>
    </Card>
  );
};

export default NewMortgagePenaltyCalculator;
