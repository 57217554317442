import React from "react";
import "./TermsPop.css"; // Import the CSS

const TermsPop = ({ onAgree, onCancel }) => {
  return (
    <>
      <div className="popup-backdrop" onClick={onCancel}></div>
      <div className="terms-pop">
        <div className="terms-content">
        <h2>Terms and Conditions for Use of Cheap Insurance Car Finder</h2>
        <p><strong>No Liability:</strong> We are not liable for any decisions or actions taken based on the information provided by this tool. The tool is offered "as is," with no guarantees of accuracy, completeness, or correctness of the data. You use this tool entirely at your own risk.</p>
        
        <p><strong>No Recommendation:</strong> This tool is purely informational and does not recommend any insurance product, service, or provider. It is your responsibility to conduct thorough research and due diligence before making any insurance decisions.</p>

        <p><strong>Data Source:</strong> The data presented in this tool is sourced from publicly available information, including the <a href="https://www.ibc.ca/insurance-basics/auto/how-cars-measure-up">Insurance Bureau of Canada</a> website. We do not claim ownership or control over this data, and it may be subject to change or become outdated.
        <ul>
            <li>Collision claims statistics across Canada</li>
            <li>Comprehensive claims statistics across Canada, including theft</li>
            <li>Direct Compensation Property Damage across Canada</li>
            <li>Accident Benefits (AB), Ontario only</li>
        </ul>
        </p>

        <p><strong>Errors and Omissions:</strong> We reserve the right to make mistakes, errors, or omissions in the information presented. We cannot guarantee that the results are error-free or completely accurate.</p>

        <p><strong>User Responsibility:</strong> You are solely responsible for verifying the information provided and making your own independent assessment before making any decisions regarding car insurance.</p>

        <p><strong>Zero Liability:</strong> In no event will we be liable for any damages, losses, or claims arising from the use of this tool. By proceeding, you accept full responsibility for your actions and any consequences thereof.</p>

        <p><strong>Use at Your Own Risk:</strong> By using this tool, you agree that it is for informational purposes only and that we bear no responsibility for the accuracy, reliability, or outcomes of using the tool.</p>

        </div>
        <div className="terms-buttons">
          <button className="cancel-button" onClick={onCancel}>
            Cancel
          </button>
          <button onClick={onAgree}>Agree</button>
        </div>
      </div>
    </>
  );
};

export default TermsPop;
